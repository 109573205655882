import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '../store';
import { logout } from '../store/actions/auth.actions';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private store: Store<State>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((error) => {
          if (error.error instanceof Error) {
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`);

            if (error.status === 401) {
              this.store.dispatch(logout());
            }
          }

          return [];
      })
    );
  }
}
