import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { faGhost, faSkullCrossbones } from '@fortawesome/free-solid-svg-icons';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'sa-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements AfterViewInit, OnInit {
  constructor() { }

  faGhost = faGhost;
  faSkullCrossbones = faSkullCrossbones;

  @Input()
  displayedColumns: string[] = ['profile_image', 'actions', 'name', 'birth', 'gender', 'country', 'createdAt', 'last_activity', 'active', 'subscription_tier', 'hidden_under_review'];
  
  @Input()
  displayedColumnsMobile: string[] = ['profile_image_mobile', 'actions_mobile', 'name'];

  @Input()
  data: User[] = [];

  @Input()
  initialSort: Sort = { active: 'createdAt', direction: 'desc' };

  @Output() userClicked = new EventEmitter<User>();
  @Output() userBanClicked = new EventEmitter<User>();
  @Output() userUnBanClicked = new EventEmitter<User>();
  @Output() userPutUnderReviewClicked = new EventEmitter<User>();
  @Output() userRemoveFromReviewClicked = new EventEmitter<User>();
  @Output() userSilentHideClicked = new EventEmitter<User>();
  @Output() userSilentUnhideClicked = new EventEmitter<User>();
  @Output() userDeleteClicked = new EventEmitter<User>();
  @Output() userBoostClicked = new EventEmitter<User>();
  @Output() userDeboostClicked = new EventEmitter<User>();

  // Sort change evnt
  @Output() sortChange = new EventEmitter<Sort>();

  @ViewChild(MatSort) sort: MatSort;

  public isMobileLayout = false;

  @HostListener("window:resize", [])
  onResize() {
    var width = window.screen.width;
    this.isMobileLayout = width < 567;
  }

  ngOnInit() {
    if (window.screen.width < 567) { // 768px portrait
      this.isMobileLayout = true;
    }
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((changedSort) => {
      this.sortChange.emit(changedSort);
    });
  }
}
