import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ToastsService } from 'src/app/lib/services/toasts.service';
import { User } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';
import * as fromStore from '../../store';

@Component({
  selector: 'sa-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  form: UntypedFormGroup;
  user: User;

  isLoading = true;
  hasError = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<fromStore.State>,
    private fb: UntypedFormBuilder,
    private usersService: UsersService,
    private toastsService: ToastsService,
  ) {

    this.form = this.fb.group({
      name: ['', [Validators.required]],
      lastname: [''],
      birth: ['', [Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])*$/)]],
      email: ['', [Validators.email]],
      phone_number: [''],
      gender: ['', [Validators.required]],
      interested_in: ['', [Validators.required]],
    });

    this.form.disable();
  }

  ngOnInit(): void {
    const userId = Number(this.route.snapshot.paramMap.get('id'));
    this.usersService.getUser(userId).pipe(
      tap(user => {
        this.isLoading = false;
        this.user = user;
        this.form.patchValue(user);

        this.form.enable();
      }),
      catchError(() => {
        this.isLoading = false;
        this.hasError = true;
        return of([]);
      })
    ).subscribe();
  }

  onSubmit() {
    if (this.form.valid) {
      this.usersService.updateUser(this.user.user_id, this.form.value).pipe(
        tap((result) => {
          this.toastsService.showSuccess('Saved user successfully.');
          this.router.navigate(['..']);
        }),
        catchError((error) => {
          this.toastsService.showDanger('Error saving user: ' + JSON.stringify(error));
          return of([]);
        })
      ).subscribe();
    }
  }
}
