import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { UsersComponent } from './components/users/users.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { TokenInterceptor } from './utils/token.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/effects/auth.effects';
import { NavComponent } from './components/nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LibModule } from './lib/lib.module';
import { UserDialogComponent } from './components/user-dialog/user-dialog.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { ReportedUsersComponent } from './components/reported-users/reported-users.component';
import { BannedUsersComponent } from './components/banned-users/banned-users.component';
import { SilentHiddenUsersComponent } from './components/silent-hidden-users/silent-hidden-users.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UsersTableComponent } from './components/users-table/users-table.component';
import { HttpErrorInterceptor } from './utils/error.interceptor';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { FilteringModule } from './modules/filtering/filtering.module';
import { UnderReviewUsersComponent } from './components/under-review-users/under-review-users.component';
import { SuspiciousUsersComponent } from './components/suspicious-users/suspicious-users.component';
import { MatchesModule } from './modules/matches/matches.module';
import { VerificationModule } from './modules/verification/verification.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UsersComponent,
    ReportedUsersComponent,
    BannedUsersComponent,
    UnderReviewUsersComponent,
    SilentHiddenUsersComponent,
    SuspiciousUsersComponent,
    NavComponent,
    UserDialogComponent,
    UserInfoComponent,
    UsersTableComponent,
    EditUserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : [],
    EffectsModule.forRoot([AuthEffects]),
    LayoutModule,
    MatMomentDateModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    MatRippleModule,
    MatDialogModule,
    MatExpansionModule,
    LibModule,
    NgbModule,
    FontAwesomeModule,
    GoogleMapsModule,
    FilteringModule,
    MatchesModule,
    VerificationModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: 'environment', useValue: environment },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
