import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Match } from '../../models/match.model';
import { Message } from '../../models/message.model';
import { MatchesService } from '../../services/matches.service';

@Component({
  selector: 'sa-match-messages',
  templateUrl: './match-messages.component.html',
  styleUrls: ['./match-messages.component.scss']
})
export class MatchMessagesComponent implements OnChanges {
  
  @Input()
  match: Match;

  messages: Message[];

  isLoading = true;
  hasError = false;

  constructor(
    private matchesService: MatchesService,
  ) { }

  ngOnChanges(): void {
    this.isLoading = true;
    this.hasError = false;
    this.messages = [];

    this.matchesService.getMessages(this.match.m_id).pipe(
      tap(messagesResponse => {
        this.isLoading = false;
        this.messages = messagesResponse.messages;
      }),
      catchError(() => {
        this.isLoading = false;
        this.hasError = true;
        return of([]);
      })
    ).subscribe();
  }
}
