import { Component, ViewChild, AfterViewInit, OnInit, HostListener } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { MatPaginator } from '@angular/material/paginator';
import { startWith, switchMap, map, catchError, tap } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users.service';
import { of, BehaviorSubject, merge } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import { ConfirmationService } from 'src/app/lib/services/confirmation.service';
import { ToastsService } from 'src/app/lib/services/toasts.service';
import { MatSort } from '@angular/material/sort';
import { ReportedUser } from 'src/app/models/reported-user.model';
import { faGhost, faSkullCrossbones } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { Filter } from 'src/app/modules/filtering/models';

@Component({
  selector: 'sa-reported-users',
  templateUrl: './reported-users.component.html',
  styleUrls: ['./reported-users.component.scss']
})
export class ReportedUsersComponent implements AfterViewInit, OnInit {

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private usersService: UsersService,
    private confirmationService: ConfirmationService,
    private toastsService: ToastsService
  ) { }

  faGhost = faGhost;
  faSkullCrossbones = faSkullCrossbones;

  query = new BehaviorSubject<string>('');
  filters = new BehaviorSubject<Filter[]>(this.getFilter());

  displayedColumns: string[] = ['profile_image', 'actions', 'name', 'gender', 'country', 'createdAt', 'last_activity', 'active', 'subscription_tier', 'hidden_under_review', 'count', 'reason'];
  displayedColumnsMobile: string[] = ['profile_image_mobile', 'actions_mobile', 'name'];
  
  data: ReportedUser[] = [];

  resultsLength = 0;
  isLoading = true;
  hasError = false;
  limit = 30;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public isMobileLayout = false;

  @HostListener("window:resize", [])
  onResize() {
    var width = window.screen.width;
    this.isMobileLayout = width < 567;
  }

  ngOnInit() {
    if (window.screen.width < 567) { // 768px portrait
      this.isMobileLayout = true;
    }
  }

  ngAfterViewInit() {
    merge(this.paginator.page, this.sort.sortChange, this.query, this.filters).pipe(
      startWith({}),
      switchMap(() => {
        this.isLoading = true;
        return this.usersService.getReportedUsers(
          this.query.value,
          this.paginator.pageIndex + 1,
          this.limit,
          `${this.sort.active}:${this.sort.direction}`,
          this.filters.value,
        );
      }),
      map(usersCollection => {
        // Flip flag to show that loading has finished.
        this.isLoading = false;
        this.hasError = false;
        this.resultsLength = usersCollection.totalCount;

        return usersCollection.results;
      }),
      catchError(() => {
        this.isLoading = false;
        this.hasError = true;
        return of([]);
      })
    ).subscribe(data => this.data = data);
  }

  getFilter(): Filter[] {
    return [];
  }

  queryChanged(e) {
    this.paginator.pageIndex = 0;
    this.query.next(e.target.value);
  }

  pageChanged(e) {
    this.paginator.pageIndex = +e.target.value - 1;
    this.query.next(this.query.value);
  }

  onFiltersUpdated(filters) {
    this.filters.next(filters);
  }

  userClicked(reportedUser: ReportedUser) {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      data: {
        user: reportedUser.user,
        reportedUser: reportedUser,
      },
      minWidth: 400,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (result === 'edit') {
        this.router.navigate(['/users', reportedUser.user.user_id]);
      }
    });
  }

  userBanClicked(user: User) {
    this.usersService.banUser(user).pipe(
      tap((result) => {
        this.toastsService.showSuccess('Banned user successfully.');
        this.query.next(this.query.value);
      }),
      catchError((error) => {
        this.toastsService.showDanger('Error banning user: ' + JSON.stringify(error));
        return of([]);
      })
    ).subscribe();
  }

  userUnBanClicked(user: User) {
    this.confirmationService.askForConfirmation(`Unban ${user.name} ${user.lastname}?`, `Are you sure you want to unban ${user.name} ${user.lastname}?`)
      .then(() => {
        this.usersService.unBanUser(user).pipe(
          tap((result) => {
            this.toastsService.showSuccess('Unbanned user successfully.');
            this.query.next(this.query.value);
          }),
          catchError((error) => {
            this.toastsService.showDanger('Error unbanning user: ' + JSON.stringify(error));
            return of([]);
          })
        ).subscribe();
      })
      .catch(() => {});
  }

  userPutUnderReviewClicked(user) {
    this.usersService.putUserUnderReview(user).pipe(
      tap((result) => {
        this.toastsService.showSuccess('Put user under review successfully.');
        this.query.next(this.query.value);
      }),
      catchError((error) => {
        this.toastsService.showDanger('Error putting user under review: ' + JSON.stringify(error));
        return of([]);
      })
    ).subscribe();
  }

  userRemoveFromReviewClicked(user) {
    this.confirmationService.askForConfirmation(`Remove ${user.name} ${user.lastname} from review?`, `Are you sure you want to remove ${user.name} ${user.lastname} from review?`)
      .then(() => {
        this.usersService.removeUserFromReview(user).pipe(
          tap((result) => {
            this.toastsService.showSuccess('Removed user from review successfully.');
            this.query.next(this.query.value);
          }),
          catchError((error) => {
            this.toastsService.showDanger('Error removing user from review: ' + JSON.stringify(error));
            return of([]);
          })
        ).subscribe();
      })
      .catch(() => {});
  }

  userSilentHideClicked(user: User) {
    this.usersService.silentHideUser(user).pipe(
      tap((result) => {
        this.toastsService.showSuccess('Silent hid user successfully.');
        this.query.next(this.query.value);
      }),
      catchError((error) => {
        this.toastsService.showDanger('Error silent hiding user: ' + JSON.stringify(error));
        return of([]);
      })
    ).subscribe();
  }

  userSilentUnhideClicked(user: User) {
    this.confirmationService.askForConfirmation(`Unhide ${user.name} ${user.lastname}?`, `Are you sure you want to unhide ${user.name} ${user.lastname}?`)
      .then(() => {
        this.usersService.silentUnhideUser(user).pipe(
          tap((result) => {
            this.toastsService.showSuccess('Unhid user successfully.');
            this.query.next(this.query.value);
          }),
          catchError((error) => {
            this.toastsService.showDanger('Error unhiding user: ' + JSON.stringify(error));
            return of([]);
          })
        ).subscribe();
      })
      .catch(() => {});
  }

  userBoostClicked(user: User) {
    this.usersService.boostUser(user).pipe(
      tap((result) => {
        this.toastsService.showSuccess('Boosted user successfully.');
        this.query.next(this.query.value);
      }),
      catchError((error) => {
        this.toastsService.showDanger('Error boosting user: ' + JSON.stringify(error));
        return of([]);
      })
    ).subscribe();
  }

  userDeboostClicked(user: User) {
    this.usersService.deboostUser(user).pipe(
      tap((result) => {
        this.toastsService.showSuccess('Deboost user successfully.');
        this.query.next(this.query.value);
      }),
      catchError((error) => {
        this.toastsService.showDanger('Error deboosting user: ' + JSON.stringify(error));
        return of([]);
      })
    ).subscribe();
  }

  userDeleteClicked(user: User) {
    this.confirmationService.askForConfirmation(`Delete ${user.name} ${user.lastname}?`, `Are you sure you want to delete ${user.name} ${user.lastname}?`)
      .then(() => {
        this.usersService.deleteUser(user).pipe(
          tap((result) => {
            this.toastsService.showSuccess('Deleted user successfully.');
            this.query.next(this.query.value);
          }),
          catchError((error) => {
            this.toastsService.showDanger('Error deleting user: ' + JSON.stringify(error));
            return of([]);
          })
        ).subscribe();
      })
      .catch(() => {});
  }

}
