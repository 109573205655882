import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'sa-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  @Input()
  user: User;
  options: google.maps.MapOptions;
  marker: google.maps.MarkerOptions;

  constructor(
    public usersService: UsersService,
  ) {}

  ngOnInit(): void {
    this.options = {
      center: {
        lat: +this.user.last_location.coordinates[1],
        lng: +this.user.last_location.coordinates[0],
      },
      zoom: 5,
    };
    this.marker = {
      position: {
        lat: +this.user.last_location.coordinates[1],
        lng: +this.user.last_location.coordinates[0],
      },
    };
  }

  notesValueChanged(newValue) {
    this.user.admin_notes = newValue;
    this.usersService.updateUser(this.user.user_id, {
      admin_notes: newValue,
    }).subscribe();
  }
}
