import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { State } from '../store';
import { selectAuthState } from '../store/selectors/auth.selectors';
import { AuthToken } from '../models/auth.model';
import { first, flatMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private store: Store<State>) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return this.store.select(selectAuthState).pipe(
            first(),
            flatMap(authState => {
              const authReq = !!authState.auth ? request.clone({
                setHeaders: { Authorization: 'Bearer ' + authState.auth.authToken.token },
              }) : request;
              return next.handle(authReq);
            }),
          );
    }
}
