import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, switchMap, tap } from 'rxjs/operators';
import { EMPTY, of, from } from 'rxjs';

import * as AuthActions from '../actions/auth.actions';
import { AuthService } from 'src/app/services/auth.service';
import { AuthToken } from 'src/app/models/auth.model';
import { User } from 'src/app/models/user.model';
import { Router } from '@angular/router';



@Injectable()
export class AuthEffects {

  constructor(private actions$: Actions, private authService: AuthService, private router: Router) {}

  authUser$ = createEffect(() => {
    return this.actions$.pipe(

      ofType(AuthActions.authUser),
      switchMap((action) => {
        return this.authService.logIn(action.email, action.password).pipe(
          switchMap((authToken: AuthToken) => {
            return from([
              AuthActions.authUserSuccess({
                authToken
              }),
              AuthActions.getUser()
            ]);
          }),
          catchError((error) => {
            return of(AuthActions.authUserFailure({ error }));
          })
        );
      })
    );
  });

  authUserSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authUserSuccess),
      switchMap(() => {
        this.router.navigate(['/users']);

        return [];
      })
    );
  });

  getMe$ = createEffect(() => {
    return this.actions$.pipe(

      ofType(AuthActions.getUser),
      switchMap((action) => {
        return this.authService.getMe().pipe(
          switchMap((user: User) => {
            return of(AuthActions.getUserSuccess({
              user,
            }));
          }),
          catchError((error) => {
            return from([
              AuthActions.getUserFailure({ error }),
              AuthActions.logout(),
            ]);
          })
        );
      })
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() => {
        this.router.navigate(['/login']);

        return [];
      })
    );
  });
}
