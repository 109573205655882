import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { UserMatchesComponent } from './components/user-matches/user-matches.component';
import { MatchMessagesComponent } from './components/match-messages/match-messages.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [UserMatchesComponent, MatchMessagesComponent],
  exports: [
    UserMatchesComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatProgressSpinnerModule,
  ]
})
export class MatchesModule { }