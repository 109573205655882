import { Component } from '@angular/core';
import { Filter, FilterType } from 'src/app/modules/filtering/models';
import { UsersComponent } from '../users/users.component';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'sa-banned-users',
  templateUrl: '../users/users.component.html',
  styleUrls: ['../users/users.component.scss']
})
export class BannedUsersComponent extends UsersComponent {

  getDisplayedColumns(): string[] {
    return ['profile_image', 'actions', 'name', 'birth', 'gender', 'country', 'createdAt', 'last_activity', 'bannedAt', 'active', 'subscription_tier'];
  }

  getFilter(): Filter[] {
    return [{
      type: FilterType.BANNED,
      value: true,
      mutable: false
    }];
  }

  getSort(): Sort {
      return {
        active: 'bannedAt',
        direction: 'desc',
      }
  }
}
