<div class="container-fluid">
  <div class="container-fluid d-flex profile-image-container">
    <img *ngFor="let profile_image of user.profile_images; let i = index" [src]="profile_image.image_url" class="rounded profile-image m-1" alt="...">
  </div>
  <mat-accordion class="example-headers-align m-2" [multi]="true">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Notes
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon>notes</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="container-fluid">
        <sa-editable-textfield label="Admin Notes" [value]="user.admin_notes" (valueChanged)="notesValueChanged($event)"></sa-editable-textfield>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Personal Info
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon>face</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="container-fluid">
        <sa-info-field label="User ID">
          {{ user.user_id }}
        </sa-info-field>
        <sa-info-field label="First Name">
          {{ user.name }}
        </sa-info-field>
        <sa-info-field label="Last Name">
          {{ user.lastname }}
        </sa-info-field>
        <sa-info-field label="Verified">
          <div *ngIf="user.is_verified"><b>Yes</b></div>
          <div *ngIf="!user.is_verified">No</div>
          <sa-verification-session-inline class="mt-4" [verificationSessionId]="user.last_verification_session_id" *ngIf="user.last_verification_session_id" ></sa-verification-session-inline>
        </sa-info-field>
        <sa-info-field label="Rating">
          {{ user.popularity_score }}
        </sa-info-field>
        <sa-info-field label="Phone Number">
          {{ user.phone_number }}
        </sa-info-field>
        <sa-info-field label="Phone Number Country">
          {{ user.phone_number_cc }}
        </sa-info-field>
        <sa-info-field label="Phone Number Carrier">
          {{ user.phone_number_carrier }}
        </sa-info-field>
        <sa-info-field label="Email">
          {{ user.email }}
        </sa-info-field>
        <sa-info-field label="Status">
          {{ user.active ? 'Active' : 'Inactive'}}
        </sa-info-field>
        <sa-info-field label="Premium User">
          {{ user.is_premium ? 'Yes' : 'No'}}
        </sa-info-field>
        <sa-info-field label="Select User">
          {{ user.is_select ? 'Yes' : 'No'}}
        </sa-info-field>
        <sa-info-field label="Gender">
          {{ user.gender }}
        </sa-info-field>
        <sa-info-field label="Birth Date">
          {{ user.birth }}
        </sa-info-field>
        <sa-info-field label="Device Type">
          {{ user.device_type }}
        </sa-info-field>
        <sa-info-field label="Facebook ID">
          {{ user.fid }}
        </sa-info-field>
        <sa-info-field label="Facebook Access Token">
          {{ user.fb_access_token }}
        </sa-info-field>
        <sa-info-field label="Firebase UID">
          {{ user.firebase_uid }}
        </sa-info-field>
        <sa-info-field label="FCM ID">
          {{ user.gcm_reg_id }}
        </sa-info-field>
        <sa-info-field label="Last Activity">
          {{ user.last_activity | date: 'medium' }}
        </sa-info-field>
        <sa-info-field label="Last IP">
          {{ user.last_ip }}
        </sa-info-field>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Profile
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon>info</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="container-fluid">
        <sa-info-field label="About">
          {{ user.about }}
        </sa-info-field>
        <sa-info-field label="Height">
          {{ user.height }}
        </sa-info-field>
        <sa-info-field label="Education">
          {{ user.education }}
        </sa-info-field>
        <sa-info-field label="Career">
          {{ user.career }}
        </sa-info-field>
        <sa-info-field label="Languages">
          {{ user.languages }}
        </sa-info-field>
        <sa-info-field label="Looking For">
          {{ user.looking_for }}
        </sa-info-field>
        <sa-info-field label="Favorite Food">
          {{ user.favorite_food }}
        </sa-info-field>
        <sa-info-field label="I Like Someone">
          {{ user.i_like_someone }}
        </sa-info-field>
        <sa-info-field label="Personality">
          {{ user.personality }}
        </sa-info-field>
        <sa-info-field label="Country Born">
          {{ user.country_born }}
        </sa-info-field>
        <sa-info-field label="Drink Pref">
          {{ user.drink_pref }}
        </sa-info-field>
        <sa-info-field label="Smoke Pref">
          {{ user.smoke_pref }}
        </sa-info-field>
        <sa-info-field label="Instagram">
          {{ user.instagram }}
        </sa-info-field>
        <sa-info-field label="TikTok">
          {{ user.tiktok }}
        </sa-info-field>
        <sa-info-field label="Snapchat">
          {{ user.snapchat }}
        </sa-info-field>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Discovery Settings
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon>person_search</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="container-fluid">
        <sa-info-field label="Interested In">
          {{ user.interested_in }}
        </sa-info-field>
        <sa-info-field label="Age Range">
          {{ user.min_age }} - {{ user.max_age }}
        </sa-info-field>
        <sa-info-field label="Distance">
          {{ user.range }}
        </sa-info-field>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Moderation States
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon>shield</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="container-fluid">
        <sa-info-field label="Waitlisted">
          {{ user.hidden_under_review ? 'Yes' : 'No'}}
        </sa-info-field>
        <sa-info-field label="Banned">
          {{ user.is_banned ? 'Yes' : 'No'}}
        </sa-info-field>
        <sa-info-field label="Silent Hidden">
          {{ user.hidden_silent ? 'Yes' : 'No'}}
        </sa-info-field>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Location Information
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon>near_me</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="container-fluid">
        <sa-info-field label="Last Location" *ngIf="user.last_location">
          <div>
            <google-map [options]="options">
              <map-marker
                [options]="marker"
              >
              </map-marker>
            </google-map>
          </div>
        </sa-info-field>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Record Information
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon>storage</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="container-fluid">
        <sa-info-field label="Created At">
          {{ user.createdAt | date: 'medium'}}
        </sa-info-field>
        <sa-info-field label="Updated At">
          {{ user.updatedAt | date: 'medium'}}
        </sa-info-field>
        <sa-info-field label="Deleted At">
          {{ user.deletedAt | date: 'medium'}}
        </sa-info-field>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

