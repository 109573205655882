<div class="container d-flex justify-content-center">
  <mat-card appearance="outlined" class="w-100 login-card my-5">
    <mat-card-header>
      <mat-card-title>Login</mat-card-title>
    </mat-card-header>
    <mat-card-content>
  
      <form class="example-form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field class="w-100">
          <mat-label>Email</mat-label>
          <input matInput [formControl]="form.controls.email">
          <mat-error *ngIf="form.controls.email.hasError('email') && !form.controls.email.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="form.controls.email.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="w-100">
          <mat-label>Password</mat-label>
          <input matInput type='password' [formControl]="form.controls.password">
          <mat-error *ngIf="form.controls.password.hasError('required')">
            Password is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <button type="submit" mat-flat-button color="primary" class='w-100'>Login</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>