import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
@Component({
  selector: 'sa-editable-textfield',
  templateUrl: './editable-textfield.component.html',
  styleUrls: ['./editable-textfield.component.scss']
})
export class EditableTextFieldComponent implements AfterViewInit {
  @Input()
  label: string;
  @Input()
  value: string;

  @Output() valueChanged = new EventEmitter<string>();

  value$: BehaviorSubject<string>;

  constructor() { }

  ngAfterViewInit(): void {
      this.value$ = new BehaviorSubject(this.value);
      this.value$.pipe(
        debounceTime(300), // Debounce for 300ms
        filter(x => x !== this.value) // Don't emit same value as initial
      ).subscribe(x => this.valueChanged.next(x));
  }

  onValueChanged(event) {
    this.value$.next(event.target.value);
  }
}
