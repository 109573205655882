export enum FilterType {
  COUNTRY = 'Current Country',
  COUNTRY_BORN = 'Country Born',
  BACKGROUND = 'Background',
  SUBSCRIPTION_TIER = 'Subscription Tier',
  GENDER = 'Gender',
  INTERESTED_IN = 'Interested In',
  STATUS = 'Status',
  BANNED = 'Banned',
  SILENT_HIDDEN = 'Silent Hidden',
  HIDDEN_UNDER_REVIEW = 'Hidden Under Review',
  VERIFIED = 'Verified',
  BORN_AFTER = 'Born After',
  BORN_BEFORE = 'Born Before',
  BORN_ON = 'Born On',
};

export enum ValueType {
  CONSTANT,
  DATE,
};

export interface Filter {
  temp_id?: string;
  type?: FilterType;
  value?: string | boolean | number;
  mutable: boolean;
};

export const countryValues = {
  type: ValueType.CONSTANT,
  values: [
    { title: 'Afghanistan', value: 'AFG' },
    { title: 'Åland Islands', value: 'ALA' },
    { title: 'Albania', value: 'ALB' },
    { title: 'Algeria', value: 'DZA' },
    { title: 'American Samoa', value: 'ASM' },
    { title: 'Andorra', value: 'AND' },
    { title: 'Angola', value: 'AGO' },
    { title: 'Anguilla', value: 'AIA' },
    { title: 'Antarctica', value: 'ATA' },
    { title: 'Antigua and Barbuda', value: 'ATG' },
    { title: 'Argentina', value: 'ARG' },
    { title: 'Armenia', value: 'ARM' },
    { title: 'Aruba', value: 'ABW' },
    { title: 'Australia', value: 'AUS' },
    { title: 'Austria', value: 'AUT' },
    { title: 'Azerbaijan', value: 'AZE' },
    { title: 'Bahamas', value: 'BHS' },
    { title: 'Bahrain', value: 'BHR' },
    { title: 'Bangladesh', value: 'BGD' },
    { title: 'Barbados', value: 'BRB' },
    { title: 'Belarus', value: 'BLR' },
    { title: 'Belgium', value: 'BEL' },
    { title: 'Belize', value: 'BLZ' },
    { title: 'Benin', value: 'BEN' },
    { title: 'Bermuda', value: 'BMU' },
    { title: 'Bhutan', value: 'BTN' },
    { title: 'Bolivia (Plurinational State of)', value: 'BOL' },
    { title: 'Bonaire, Sint Eustatius and Saba', value: 'BES' },
    { title: 'Bosnia and Herzegovina', value: 'BIH' },
    { title: 'Botswana', value: 'BWA' },
    { title: 'Bouvet Island', value: 'BVT' },
    { title: 'Brazil', value: 'BRA' },
    { title: 'British Indian Ocean Territory', value: 'IOT' },
    { title: 'Brunei Darussalam', value: 'BRN' },
    { title: 'Bulgaria', value: 'BGR' },
    { title: 'Burkina Faso', value: 'BFA' },
    { title: 'Burundi', value: 'BDI' },
    { title: 'Cabo Verde', value: 'CPV' },
    { title: 'Cambodia', value: 'KHM' },
    { title: 'Cameroon', value: 'CMR' },
    { title: 'Canada', value: 'CAN' },
    { title: 'Cayman Islands', value: 'CYM' },
    { title: 'Central African Republic', value: 'CAF' },
    { title: 'Chad', value: 'TCD' },
    { title: 'Chile', value: 'CHL' },
    { title: 'China', value: 'CHN' },
    { title: 'Christmas Island', value: 'CXR' },
    { title: 'Cocos (Keeling) Islands', value: 'CCK' },
    { title: 'Colombia', value: 'COL' },
    { title: 'Comoros', value: 'COM' },
    { title: 'Congo', value: 'COG' },
    { title: 'Congo, Democratic Republic of the', value: 'COD' },
    { title: 'Cook Islands', value: 'COK' },
    { title: 'Costa Rica', value: 'CRI' },
    { title: 'Côte d\'Ivoire', value: 'CIV' },
    { title: 'Croatia', value: 'HRV' },
    { title: 'Cuba', value: 'CUB' },
    { title: 'Curaçao', value: 'CUW' },
    { title: 'Cyprus', value: 'CYP' },
    { title: 'Czechia', value: 'CZE' },
    { title: 'Denmark', value: 'DNK' },
    { title: 'Djibouti', value: 'DJI' },
    { title: 'Dominica', value: 'DMA' },
    { title: 'Dominican Republic', value: 'DOM' },
    { title: 'Ecuador', value: 'ECU' },
    { title: 'Egypt', value: 'EGY' },
    { title: 'El Salvador', value: 'SLV' },
    { title: 'Equatorial Guinea', value: 'GNQ' },
    { title: 'Eritrea', value: 'ERI' },
    { title: 'Estonia', value: 'EST' },
    { title: 'Eswatini', value: 'SWZ' },
    { title: 'Ethiopia', value: 'ETH' },
    { title: 'Falkland Islands (Malvinas)', value: 'FLK' },
    { title: 'Faroe Islands', value: 'FRO' },
    { title: 'Fiji', value: 'FJI' },
    { title: 'Finland', value: 'FIN' },
    { title: 'France', value: 'FRA' },
    { title: 'French Guiana', value: 'GUF' },
    { title: 'French Polynesia', value: 'PYF' },
    { title: 'French Southern Territories', value: 'ATF' },
    { title: 'Gabon', value: 'GAB' },
    { title: 'Gambia', value: 'GMB' },
    { title: 'Georgia', value: 'GEO' },
    { title: 'Germany', value: 'DEU' },
    { title: 'Ghana', value: 'GHA' },
    { title: 'Gibraltar', value: 'GIB' },
    { title: 'Greece', value: 'GRC' },
    { title: 'Greenland', value: 'GRL' },
    { title: 'Grenada', value: 'GRD' },
    { title: 'Guadeloupe', value: 'GLP' },
    { title: 'Guam', value: 'GUM' },
    { title: 'Guatemala', value: 'GTM' },
    { title: 'Guernsey', value: 'GGY' },
    { title: 'Guinea', value: 'GIN' },
    { title: 'Guinea-Bissau', value: 'GNB' },
    { title: 'Guyana', value: 'GUY' },
    { title: 'Haiti', value: 'HTI' },
    { title: 'Heard Island and McDonald Islands', value: 'HMD' },
    { title: 'Holy See', value: 'VAT' },
    { title: 'Honduras', value: 'HND' },
    { title: 'Hong Kong', value: 'HKG' },
    { title: 'Hungary', value: 'HUN' },
    { title: 'Iceland', value: 'ISL' },
    { title: 'India', value: 'IND' },
    { title: 'Indonesia', value: 'IDN' },
    { title: 'Iran (Islamic Republic of)', value: 'IRN' },
    { title: 'Iraq', value: 'IRQ' },
    { title: 'Ireland', value: 'IRL' },
    { title: 'Isle of Man', value: 'IMN' },
    { title: 'Israel', value: 'ISR' },
    { title: 'Italy', value: 'ITA' },
    { title: 'Jamaica', value: 'JAM' },
    { title: 'Japan', value: 'JPN' },
    { title: 'Jersey', value: 'JEY' },
    { title: 'Jordan', value: 'JOR' },
    { title: 'Kazakhstan', value: 'KAZ' },
    { title: 'Kenya', value: 'KEN' },
    { title: 'Kiribati', value: 'KIR' },
    { title: 'Korea (Democratic People\'s Republic of)', value: 'PRK' },
    { title: 'Korea, Republic of', value: 'KOR' },
    { title: 'Kuwait', value: 'KWT' },
    { title: 'Kyrgyzstan', value: 'KGZ' },
    { title: 'Lao People\'s Democratic Republic', value: 'LAO' },
    { title: 'Latvia', value: 'LVA' },
    { title: 'Lebanon', value: 'LBN' },
    { title: 'Lesotho', value: 'LSO' },
    { title: 'Liberia', value: 'LBR' },
    { title: 'Libya', value: 'LBY' },
    { title: 'Liechtenstein', value: 'LIE' },
    { title: 'Lithuania', value: 'LTU' },
    { title: 'Luxembourg', value: 'LUX' },
    { title: 'Macao', value: 'MAC' },
    { title: 'Madagascar', value: 'MDG' },
    { title: 'Malawi', value: 'MWI' },
    { title: 'Malaysia', value: 'MYS' },
    { title: 'Maldives', value: 'MDV' },
    { title: 'Mali', value: 'MLI' },
    { title: 'Malta', value: 'MLT' },
    { title: 'Marshall Islands', value: 'MHL' },
    { title: 'Martinique', value: 'MTQ' },
    { title: 'Mauritania', value: 'MRT' },
    { title: 'Mauritius', value: 'MUS' },
    { title: 'Mayotte', value: 'MYT' },
    { title: 'Mexico', value: 'MEX' },
    { title: 'Micronesia (Federated States of)', value: 'FSM' },
    { title: 'Moldova, Republic of', value: 'MDA' },
    { title: 'Monaco', value: 'MCO' },
    { title: 'Mongolia', value: 'MNG' },
    { title: 'Montenegro', value: 'MNE' },
    { title: 'Montserrat', value: 'MSR' },
    { title: 'Morocco', value: 'MAR' },
    { title: 'Mozambique', value: 'MOZ' },
    { title: 'Myanmar', value: 'MMR' },
    { title: 'Namibia', value: 'NAM' },
    { title: 'Nauru', value: 'NRU' },
    { title: 'Nepal', value: 'NPL' },
    { title: 'Netherlands', value: 'NLD' },
    { title: 'New Caledonia', value: 'NCL' },
    { title: 'New Zealand', value: 'NZL' },
    { title: 'Nicaragua', value: 'NIC' },
    { title: 'Niger', value: 'NER' },
    { title: 'Nigeria', value: 'NGA' },
    { title: 'Niue', value: 'NIU' },
    { title: 'Norfolk Island', value: 'NFK' },
    { title: 'North Macedonia', value: 'MKD' },
    { title: 'Northern Mariana Islands', value: 'MNP' },
    { title: 'Norway', value: 'NOR' },
    { title: 'Oman', value: 'OMN' },
    { title: 'Pakistan', value: 'PAK' },
    { title: 'Palau', value: 'PLW' },
    { title: 'Palestine, State of', value: 'PSE' },
    { title: 'Panama', value: 'PAN' },
    { title: 'Papua New Guinea', value: 'PNG' },
    { title: 'Paraguay', value: 'PRY' },
    { title: 'Peru', value: 'PER' },
    { title: 'Philippines', value: 'PHL' },
    { title: 'Pitcairn', value: 'PCN' },
    { title: 'Poland', value: 'POL' },
    { title: 'Portugal', value: 'PRT' },
    { title: 'Puerto Rico', value: 'PRI' },
    { title: 'Qatar', value: 'QAT' },
    { title: 'Réunion', value: 'REU' },
    { title: 'Romania', value: 'ROU' },
    { title: 'Russian Federation', value: 'RUS' },
    { title: 'Rwanda', value: 'RWA' },
    { title: 'Saint Barthélemy', value: 'BLM' },
    { title: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SHN' },
    { title: 'Saint Kitts and Nevis', value: 'KNA' },
    { title: 'Saint Lucia', value: 'LCA' },
    { title: 'Saint Martin (French part)', value: 'MAF' },
    { title: 'Saint Pierre and Miquelon', value: 'SPM' },
    { title: 'Saint Vincent and the Grenadines', value: 'VCT' },
    { title: 'Samoa', value: 'WSM' },
    { title: 'San Marino', value: 'SMR' },
    { title: 'Sao Tome and Principe', value: 'STP' },
    { title: 'Saudi Arabia', value: 'SAU' },
    { title: 'Senegal', value: 'SEN' },
    { title: 'Serbia', value: 'SRB' },
    { title: 'Seychelles', value: 'SYC' },
    { title: 'Sierra Leone', value: 'SLE' },
    { title: 'Singapore', value: 'SGP' },
    { title: 'Sint Maarten (Dutch part)', value: 'SXM' },
    { title: 'Slovakia', value: 'SVK' },
    { title: 'Slovenia', value: 'SVN' },
    { title: 'Solomon Islands', value: 'SLB' },
    { title: 'Somalia', value: 'SOM' },
    { title: 'South Africa', value: 'ZAF' },
    { title: 'South Georgia and the South Sandwich Islands', value: 'SGS' },
    { title: 'South Sudan', value: 'SSD' },
    { title: 'Spain', value: 'ESP' },
    { title: 'Sri Lanka', value: 'LKA' },
    { title: 'Sudan', value: 'SDN' },
    { title: 'Suriname', value: 'SUR' },
    { title: 'Svalbard and Jan Mayen', value: 'SJM' },
    { title: 'Sweden', value: 'SWE' },
    { title: 'Switzerland', value: 'CHE' },
    { title: 'Syrian Arab Republic', value: 'SYR' },
    { title: 'Taiwan, Province of China', value: 'TWN' },
    { title: 'Tajikistan', value: 'TJK' },
    { title: 'Tanzania, United Republic of', value: 'TZA' },
    { title: 'Thailand', value: 'THA' },
    { title: 'Timor-Leste', value: 'TLS' },
    { title: 'Togo', value: 'TGO' },
    { title: 'Tokelau', value: 'TKL' },
    { title: 'Tonga', value: 'TON' },
    { title: 'Trinidad and Tobago', value: 'TTO' },
    { title: 'Tunisia', value: 'TUN' },
    { title: 'Turkey', value: 'TUR' },
    { title: 'Turkmenistan', value: 'TKM' },
    { title: 'Turks and Caicos Islands', value: 'TCA' },
    { title: 'Tuvalu', value: 'TUV' },
    { title: 'Uganda', value: 'UGA' },
    { title: 'Ukraine', value: 'UKR' },
    { title: 'United Arab Emirates', value: 'ARE' },
    { title: 'United Kingdom of Great Britain and Northern Ireland', value: 'GBR' },
    { title: 'United States of America', value: 'USA' },
    { title: 'United States Minor Outlying Islands', value: 'UMI' },
    { title: 'Uruguay', value: 'URY' },
    { title: 'Uzbekistan', value: 'UZB' },
    { title: 'Vanuatu', value: 'VUT' },
    { title: 'Venezuela (Bolivarian Republic of)', value: 'VEN' },
    { title: 'Viet Nam', value: 'VNM' },
    { title: 'Virgin Islands (British)', value: 'VGB' },
    { title: 'Virgin Islands (U.S.)', value: 'VIR' },
    { title: 'Wallis and Futuna', value: 'WLF' },
    { title: 'Western Sahara', value: 'ESH' },
    { title: 'Yemen', value: 'YEM' },
    { title: 'Zambia', value: 'ZMB' },
    { title: 'Zimbabwe', value: 'ZWE' },
  ],
};

export const countryLocalizedValues = {
  type: ValueType.CONSTANT,
  values: [
    { title: 'Afghanistan', value: 'AFGHANISTAN' },
    { title: 'Albania', value: 'ALBANIA' },
    { title: 'Algeria', value: 'ALGERIA' },
    { title: 'American Samoa', value: 'AMERICAN_SAMOA' },
    { title: 'Andorra', value: 'ANDORRA' },
    { title: 'Angola', value: 'ANGOLA' },
    { title: 'Anguilla', value: 'ANGUILLA' },
    { title: 'Antigua and Barbuda', value: 'ANTIGUA_AND_BARBUDA' },
    { title: 'Argentina', value: 'ARGENTINA' },
    { title: 'Armenia', value: 'ARMENIA' },
    { title: 'Aruba', value: 'ARUBA' },
    { title: 'Australia', value: 'AUSTRALIA' },
    { title: 'Austria', value: 'AUSTRIA' },
    { title: 'Azerbaijan', value: 'AZERBAIJAN' },
    { title: 'Bahamas', value: 'BAHAMAS' },
    { title: 'Bahrain', value: 'BAHRAIN' },
    { title: 'Bangladesh', value: 'BANGLADESH' },
    { title: 'Barbados', value: 'BARBADOS' },
    { title: 'Belarus', value: 'BELARUS' },
    { title: 'Belgium', value: 'BELGIUM' },
    { title: 'Belize', value: 'BELIZE' },
    { title: 'Benin', value: 'BENIN' },
    { title: 'Bermuda', value: 'BERMUDA' },
    { title: 'Bhutan', value: 'BHUTAN' },
    { title: 'Bolivia', value: 'BOLIVIA' },
    { title: 'Bosnia and Herzegovina', value: 'BOSNIA_AND_HERZEGOVINA' },
    { title: 'Botswana', value: 'BOTSWANA' },
    { title: 'Brazil', value: 'BRAZIL' },
    { title: 'British Virgin Islands', value: 'BRITISH_VIRGIN_ISLANDS' },
    { title: 'Brunei', value: 'BRUNEI' },
    { title: 'Bulgaria', value: 'BULGARIA' },
    { title: 'Burkina Faso', value: 'BURKINA_FASO' },
    { title: 'Burundi', value: 'BURUNDI' },
    { title: 'Cambodia', value: 'CAMBODIA' },
    { title: 'Cameroon', value: 'CAMEROON' },
    { title: 'Canada', value: 'CANADA' },
    { title: 'Cape Verde', value: 'CAPE_VERDE' },
    { title: 'Cayman Islands', value: 'CAYMAN_ISLANDS' },
    { title: 'Central African Republic', value: 'CENTRAL_AFRICAN_REPUBLIC' },
    { title: 'Chad', value: 'CHAD' },
    { title: 'Chile', value: 'CHILE' },
    { title: 'China', value: 'CHINA' },
    { title: 'Colombia', value: 'COLOMBIA' },
    { title: 'Comoros', value: 'COMOROS' },
    { title: 'Cook Islands', value: 'COOK_ISLANDS' },
    { title: 'Costa Rica', value: 'COSTA_RICA' },
    { title: 'Côte d\'Ivoire', value: 'CÔTE_D’IVOIRE' },
    { title: 'Croatia', value: 'CROATIA' },
    { title: 'Cuba', value: 'CUBA' },
    { title: 'Curacao', value: 'CURACAO' },
    { title: 'Cyprus', value: 'CYPRUS' },
    { title: 'Czech Republic', value: 'CZECH_REPUBLIC' },
    { title: 'Denmark', value: 'DENMARK' },
    { title: 'Djibouti', value: 'DJIBOUTI' },
    { title: 'Dominica', value: 'DOMINICA' },
    { title: 'Dominican Republic', value: 'DOMINICAN_REPUBLIC' },
    { title: 'DR Congo', value: 'DR_CONGO' },
    { title: 'Ecuador', value: 'ECUADOR' },
    { title: 'Egypt', value: 'EGYPT' },
    { title: 'El Salvador', value: 'EL_SALVADOR' },
    { title: 'Equatorial Guinea', value: 'EQUATORIAL_GUINEA' },
    { title: 'Eritrea', value: 'ERITREA' },
    { title: 'Estonia', value: 'ESTONIA' },
    { title: 'Eswatini', value: 'ESWATINI' },
    { title: 'Ethiopia', value: 'ETHIOPIA' },
    { title: 'Falkland Islands', value: 'FALKLAND_ISLANDS' },
    { title: 'Faroe Islands', value: 'FAROE_ISLANDS' },
    { title: 'Fiji', value: 'FIJI' },
    { title: 'Finland', value: 'FINLAND' },
    { title: 'France', value: 'FRANCE' },
    { title: 'French Guiana', value: 'FRENCH_GUIANA' },
    { title: 'French Polynesia', value: 'FRENCH_POLYNESIA' },
    { title: 'Gabon', value: 'GABON' },
    { title: 'Gambia', value: 'GAMBIA' },
    { title: 'Georgia', value: 'GEORGIA' },
    { title: 'Germany', value: 'GERMANY' },
    { title: 'Ghana', value: 'GHANA' },
    { title: 'Gibraltar', value: 'GIBRALTAR' },
    { title: 'Greece', value: 'GREECE' },
    { title: 'Greenland', value: 'GREENLAND' },
    { title: 'Grenada', value: 'GRENADA' },
    { title: 'Guadeloupe', value: 'GUADELOUPE' },
    { title: 'Guam', value: 'GUAM' },
    { title: 'Guatemala', value: 'GUATEMALA' },
    { title: 'Guernsey', value: 'GUERNSEY' },
    { title: 'Guinea', value: 'GUINEA' },
    { title: 'Guinea-Bissau', value: 'GUINEA-BISSAU' },
    { title: 'Guyana', value: 'GUYANA' },
    { title: 'Haiti', value: 'HAITI' },
    { title: 'Honduras', value: 'HONDURAS' },
    { title: 'Hong Kong', value: 'HONG_KONG' },
    { title: 'Hungary', value: 'HUNGARY' },
    { title: 'Iceland', value: 'ICELAND' },
    { title: 'India', value: 'INDIA' },
    { title: 'Indonesia', value: 'INDONESIA' },
    { title: 'Iran', value: 'IRAN' },
    { title: 'Iraq', value: 'IRAQ' },
    { title: 'Ireland', value: 'IRELAND' },
    { title: 'Isle of Man', value: 'ISLE_OF_MAN' },
    { title: 'Israel', value: 'ISRAEL' },
    { title: 'Italy', value: 'ITALY' },
    { title: 'Jamaica', value: 'JAMAICA' },
    { title: 'Japan', value: 'JAPAN' },
    { title: 'Jersey', value: 'JERSEY' },
    { title: 'Jordan', value: 'JORDAN' },
    { title: 'Kazakhstan', value: 'KAZAKHSTAN' },
    { title: 'Kenya', value: 'KENYA' },
    { title: 'Kiribati', value: 'KIRIBATI' },
    { title: 'Kuwait', value: 'KUWAIT' },
    { title: 'Kyrgyzstan', value: 'KYRGYZSTAN' },
    { title: 'Laos', value: 'LAOS' },
    { title: 'Latvia', value: 'LATVIA' },
    { title: 'Lebanon', value: 'LEBANON' },
    { title: 'Lesotho', value: 'LESOTHO' },
    { title: 'Liberia', value: 'LIBERIA' },
    { title: 'Libya', value: 'LIBYA' },
    { title: 'Liechtenstein', value: 'LIECHTENSTEIN' },
    { title: 'Lithuania', value: 'LITHUANIA' },
    { title: 'Luxembourg', value: 'LUXEMBOURG' },
    { title: 'Macau', value: 'MACAU' },
    { title: 'Madagascar', value: 'MADAGASCAR' },
    { title: 'Malawi', value: 'MALAWI' },
    { title: 'Malaysia', value: 'MALAYSIA' },
    { title: 'Maldives', value: 'MALDIVES' },
    { title: 'Mali', value: 'MALI' },
    { title: 'Malta', value: 'MALTA' },
    { title: 'Marshall Islands', value: 'MARSHALL_ISLANDS' },
    { title: 'Martinique', value: 'MARTINIQUE' },
    { title: 'Mauritania', value: 'MAURITANIA' },
    { title: 'Mauritius', value: 'MAURITIUS' },
    { title: 'Mayotte', value: 'MAYOTTE' },
    { title: 'Mexico', value: 'MEXICO' },
    { title: 'Micronesia', value: 'MICRONESIA' },
    { title: 'Moldova', value: 'MOLDOVA' },
    { title: 'Monaco', value: 'MONACO' },
    { title: 'Mongolia', value: 'MONGOLIA' },
    { title: 'Montenegro', value: 'MONTENEGRO' },
    { title: 'Montserrat', value: 'MONTSERRAT' },
    { title: 'Morocco', value: 'MOROCCO' },
    { title: 'Mozambique', value: 'MOZAMBIQUE' },
    { title: 'Myanmar', value: 'MYANMAR' },
    { title: 'Namibia', value: 'NAMIBIA' },
    { title: 'Nauru', value: 'NAURU' },
    { title: 'Nepal', value: 'NEPAL' },
    { title: 'Netherlands', value: 'NETHERLANDS' },
    { title: 'New Caledonia', value: 'NEW_CALEDONIA' },
    { title: 'New Zealand', value: 'NEW_ZEALAND' },
    { title: 'Nicaragua', value: 'NICARAGUA' },
    { title: 'Niger', value: 'NIGER' },
    { title: 'Nigeria', value: 'NIGERIA' },
    { title: 'Niue', value: 'NIUE' },
    { title: 'North Korea', value: 'NORTH_KOREA' },
    { title: 'North Macedonia', value: 'NORTH_MACEDONIA' },
    { title: 'Northern Mariana Islands', value: 'NORTHERN_MARIANA_ISLANDS' },
    { title: 'Norway', value: 'NORWAY' },
    { title: 'Oman', value: 'OMAN' },
    { title: 'Pakistan', value: 'PAKISTAN' },
    { title: 'Palau', value: 'PALAU' },
    { title: 'Palestine', value: 'PALESTINE' },
    { title: 'Panama', value: 'PANAMA' },
    { title: 'Papua New Guinea', value: 'PAPUA_NEW_GUINEA' },
    { title: 'Paraguay', value: 'PARAGUAY' },
    { title: 'Peru', value: 'PERU' },
    { title: 'Philippines', value: 'PHILIPPINES' },
    { title: 'Poland', value: 'POLAND' },
    { title: 'Portugal', value: 'PORTUGAL' },
    { title: 'Puerto Rico', value: 'PUERTO_RICO' },
    { title: 'Qatar', value: 'QATAR' },
    { title: 'Republic of the Congo', value: 'REPUBLIC_OF_THE_CONGO' },
    { title: 'Reunion', value: 'REUNION' },
    { title: 'Romania', value: 'ROMANIA' },
    { title: 'Russia', value: 'RUSSIA' },
    { title: 'Rwanda', value: 'RWANDA' },
    { title: 'Saint Barthelemy', value: 'SAINT_BARTHELEMY' },
    { title: 'Saint Kitts and Nevis', value: 'SAINT_KITTS_AND_NEVIS' },
    { title: 'Saint Lucia', value: 'SAINT_LUCIA' },
    { title: 'Saint Martin', value: 'SAINT_MARTIN' },
    { title: 'Saint Pierre and Miquelon', value: 'SAINT_PIERRE_AND_MIQUELON' },
    { title: 'Saint Vincent and the Grenadines', value: 'SAINT_VINCENT_AND_THE_GRENADINES' },
    { title: 'Samoa', value: 'SAMOA' },
    { title: 'San Marino', value: 'SAN_MARINO' },
    { title: 'Sao Tome and Principe', value: 'SAO_TOME_AND_PRINCIPE' },
    { title: 'Saudi Arabia', value: 'SAUDI_ARABIA' },
    { title: 'Senegal', value: 'SENEGAL' },
    { title: 'Serbia', value: 'SERBIA' },
    { title: 'Seychelles', value: 'SEYCHELLES' },
    { title: 'Sierra Leone', value: 'SIERRA_LEONE' },
    { title: 'Singapore', value: 'SINGAPORE' },
    { title: 'Sint Maarten', value: 'SINT_MAARTEN' },
    { title: 'Slovakia', value: 'SLOVAKIA' },
    { title: 'Slovenia', value: 'SLOVENIA' },
    { title: 'Solomon Islands', value: 'SOLOMON_ISLANDS' },
    { title: 'Somalia', value: 'SOMALIA' },
    { title: 'South Africa', value: 'SOUTH_AFRICA' },
    { title: 'South Korea', value: 'SOUTH_KOREA' },
    { title: 'South Sudan', value: 'SOUTH_SUDAN' },
    { title: 'Spain', value: 'SPAIN' },
    { title: 'Sri Lanka', value: 'SRI_LANKA' },
    { title: 'Sudan', value: 'SUDAN' },
    { title: 'Suriname', value: 'SURINAME' },
    { title: 'Sweden', value: 'SWEDEN' },
    { title: 'Switzerland', value: 'SWITZERLAND' },
    { title: 'Syria', value: 'SYRIA' },
    { title: 'Taiwan', value: 'TAIWAN' },
    { title: 'Tajikistan', value: 'TAJIKISTAN' },
    { title: 'Tanzania', value: 'TANZANIA' },
    { title: 'Thailand', value: 'THAILAND' },
    { title: 'Timor-Leste', value: 'TIMOR-LESTE' },
    { title: 'Togo', value: 'TOGO' },
    { title: 'Tokelau', value: 'TOKELAU' },
    { title: 'Tonga', value: 'TONGA' },
    { title: 'Trinidad and Tobago', value: 'TRINIDAD_AND_TOBAGO' },
    { title: 'Tunisia', value: 'TUNISIA' },
    { title: 'Turkey', value: 'TURKEY' },
    { title: 'Turkmenistan', value: 'TURKMENISTAN' },
    { title: 'Turks and Caicos Islands', value: 'TURKS_AND_CAICOS_ISLANDS' },
    { title: 'Tuvalu', value: 'TUVALU' },
    { title: 'Uganda', value: 'UGANDA' },
    { title: 'Ukraine', value: 'UKRAINE' },
    { title: 'United Arab Emirates', value: 'UNITED_ARAB_EMIRATES' },
    { title: 'United Kingdom', value: 'UNITED_KINGDOM' },
    { title: 'United States', value: 'UNITED_STATES' },
    { title: 'United States Virgin Islands', value: 'UNITED_STATES_VIRGIN_ISLANDS' },
    { title: 'Uruguay', value: 'URUGUAY' },
    { title: 'Uzbekistan', value: 'UZBEKISTAN' },
    { title: 'Vanuatu', value: 'VANUATU' },
    { title: 'Vatican City', value: 'VATICAN_CITY' },
    { title: 'Venezuela', value: 'VENEZUELA' },
    { title: 'Vietnam', value: 'VIETNAM' },
    { title: 'Wallis and Futuna', value: 'WALLIS_AND_FUTUNA' },
    { title: 'Western Sahara', value: 'WESTERN_SAHARA' },
    { title: 'Yemen', value: 'YEMEN' },
    { title: 'Zambia', value: 'ZAMBIA' },
    { title: 'Zimbabwe', value: 'ZIMBABWE' },
  ]
}

export const subscriptionTierValues = {
  type: ValueType.CONSTANT,
  values: [
    { title: 'Basic', value: 0 },
    { title: 'Premium', value: 1 },
    { title: 'Select', value: 2 },
  ],
};

export const genderValues = {
  type: ValueType.CONSTANT,
  values: [
    { title: 'Male', value: 'male' },
    { title: 'Female', value: 'female' },
  ],
};

export const interestedInValues = {
  type: ValueType.CONSTANT,
  values: [
    { title: 'Male', value: 'male' },
    { title: 'Female', value: 'female' },
  ],
};

export const statusValues = {
  type: ValueType.CONSTANT,
  values: [
    { title: 'Active', value: true },
    { title: 'Inactive', value: false },
  ],
};

export const bannedValues = {
  type: ValueType.CONSTANT,
  values: [
    { title: 'Yes', value: true },
    { title: 'No', value: false },
  ],
};

export const silentHiddenValues = {
  type: ValueType.CONSTANT,
  values: [
    { title: 'Yes', value: true },
    { title: 'No', value: false },
  ],
};

export const hiddenUnderReviewValues = {
  type: ValueType.CONSTANT,
  values: [
    { title: 'Yes', value: true },
    { title: 'No', value: false },
  ],
};

export const verifiedValues = {
  type: ValueType.CONSTANT,
  values: [
    { title: 'Yes', value: true },
    { title: 'No', value: false },
  ],
};

export const bornValues = {
  type: ValueType.DATE,
};

export const optionValues: Record<FilterType, { type: ValueType, values?: { title: string, value: string | boolean | number }[] }> = {
   [FilterType.COUNTRY]: countryValues,
   [FilterType.COUNTRY_BORN]: countryLocalizedValues,
   [FilterType.BACKGROUND]: countryLocalizedValues,
   [FilterType.SUBSCRIPTION_TIER]: subscriptionTierValues,
   [FilterType.GENDER]: genderValues,
   [FilterType.INTERESTED_IN]: interestedInValues,
   [FilterType.STATUS]: statusValues,
   [FilterType.BANNED]: bannedValues,
   [FilterType.SILENT_HIDDEN]: silentHiddenValues,
   [FilterType.HIDDEN_UNDER_REVIEW]: hiddenUnderReviewValues,
   [FilterType.VERIFIED]: verifiedValues,
   [FilterType.BORN_AFTER]: bornValues,
   [FilterType.BORN_BEFORE]: bornValues,
   [FilterType.BORN_ON]: bornValues,
}