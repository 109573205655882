<table mat-table [dataSource]="data" class="users-table w-100" matSort [matSortActive]="initialSort.active" matSortDisableClear [matSortDirection]="initialSort.direction" matSortStart="desc">
  <ng-container matColumnDef="profile_image">
    <mat-header-cell mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row" class="profile-image-column">
      <div class="profile-image-container">
        <img class="profile-image m-1" *ngIf="row.profile_images[0]" [src]="row.profile_images[0].image_url"/>
        <img class="profile-image-extended m-1" *ngIf="row.profile_images[0]" [src]="row.profile_images[0].image_url"/>
      </div>
      
      <div class="profile-image-container">
        <img class="profile-image m-1" *ngIf="row.profile_images[1]" [src]="row.profile_images[1].image_url"/>
        <img class="profile-image-extended m-1" *ngIf="row.profile_images[1]" [src]="row.profile_images[1].image_url"/>
      </div>

      <div class="profile-image-container">
        <img class="profile-image m-1" *ngIf="row.profile_images[2]" [src]="row.profile_images[2].image_url"/>
        <img class="profile-image-extended m-1" *ngIf="row.profile_images[2]" [src]="row.profile_images[2].image_url"/>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="profile_image_mobile">
    <mat-header-cell mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row" class="profile-image-column">
      <div class="profile-image-container">
        <img class="profile-image m-1" *ngIf="row.profile_images[0]" [src]="row.profile_images[0].image_url"/>
        <img class="profile-image-extended m-1" *ngIf="row.profile_images[0]" [src]="row.profile_images[0].image_url"/>
      </div>
      
      <div class="profile-image-container">
        <img class="profile-image m-1" *ngIf="row.profile_images[1]" [src]="row.profile_images[1].image_url"/>
        <img class="profile-image-extended m-1" *ngIf="row.profile_images[1]" [src]="row.profile_images[1].image_url"/>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row">
      <button mat-icon-button color="primary" (click)="userClicked.emit(row)" aria-label="View">
        <mat-icon>visibility</mat-icon>
      </button>
      <button mat-icon-button color="primary" *ngIf="!row.hidden_under_review" (click)="userPutUnderReviewClicked.emit(row)" aria-label="Ban">
        <mat-icon>remove_circle</mat-icon>
      </button>
      <button mat-icon-button color="primary" *ngIf="row.hidden_under_review" (click)="userRemoveFromReviewClicked.emit(row)" aria-label="Unban">
        <mat-icon>add_circle</mat-icon>
      </button>
      <button mat-icon-button class="fa-button" color="primary" *ngIf="!row.is_banned" (click)="userBanClicked.emit(row)" aria-label="Ban">
        <fa-icon [icon]="faSkullCrossbones"></fa-icon>
      </button>
      <button mat-icon-button class="fa-button fa-button-gray" *ngIf="row.is_banned" (click)="userUnBanClicked.emit(row)" aria-label="Unban">
        <fa-icon [icon]="faSkullCrossbones"></fa-icon>
      </button>
      <button mat-icon-button class="fa-button" color="primary" *ngIf="!row.hidden_silent" (click)="userSilentHideClicked.emit(row)" aria-label="Ban">
        <fa-icon [icon]="faGhost"></fa-icon>
      </button>
      <button mat-icon-button class="fa-button fa-button-gray" *ngIf="row.hidden_silent" (click)="userSilentUnhideClicked.emit(row)" aria-label="Unban">
        <fa-icon [icon]="faGhost"></fa-icon>
      </button>
      <button mat-icon-button color="primary" (click)="userDeleteClicked.emit(row)" aria-label="Delete">
        <mat-icon>delete_forever</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="userBoostClicked.emit(row)" aria-label="Boost">
        <mat-icon>arrow_circle_up</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="userDeboostClicked.emit(row)" aria-label="Deboost">
        <mat-icon>arrow_circle_down</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions_mobile">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row">
      <button mat-icon-button color="primary" (click)="userClicked.emit(row)" aria-label="View">
        <mat-icon>visibility</mat-icon>
      </button>
      <button mat-icon-button color="primary" *ngIf="!row.hidden_under_review" (click)="userBanClicked.emit(row)" aria-label="Ban">
        <mat-icon>remove_circle</mat-icon>
      </button>
      <button mat-icon-button color="primary" *ngIf="row.hidden_under_review" (click)="userUnBanClicked.emit(row)" aria-label="Unban">
        <mat-icon>add_circle</mat-icon>
      </button>
      <button mat-icon-button class="fa-button" color="primary" *ngIf="!row.hidden_silent" (click)="userSilentHideClicked.emit(row)" aria-label="Ban">
        <fa-icon [icon]="faGhost"></fa-icon>
      </button>
      <button mat-icon-button class="fa-button fa-button-gray" *ngIf="row.hidden_silent" (click)="userSilentUnhideClicked.emit(row)" aria-label="Unban">
        <fa-icon [icon]="faGhost"></fa-icon>
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row">{{row.name}} <mat-icon color="primary" class="verified-icon" *ngIf="row.is_verified">verified</mat-icon></mat-cell>
  </ng-container>

  <ng-container matColumnDef="birth">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Birthday</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row">{{row.birth}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="gender">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Gender</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row">{{row.gender}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="country">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Country</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row">{{row.detected_country}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Signed Up</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row">{{row.createdAt | date: 'medium'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="last_activity">
    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Last Activity</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row">{{row.last_activity | date: 'medium'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="bannedAt">
    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Banned At</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row">{{row.bannedAt | date: 'medium'}}</mat-cell>
  </ng-container>


  <ng-container matColumnDef="active">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row">{{row.active ? 'Active' : 'Inactive'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="subscription_tier">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Subscription Tier</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row">{{row.is_select ? 'Select' : (row.is_premium ? 'Premium' : 'Basic')}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="hidden_under_review">
    <mat-header-cell mat-header-cell *matHeaderCellDef>Under Review</mat-header-cell>
    <mat-cell mat-cell *matCellDef="let row">{{row.hidden_under_review ? 'Yes' : 'No'}}</mat-cell>
  </ng-container>
  

  <mat-header-row  *matHeaderRowDef="isMobileLayout ? displayedColumnsMobile : displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: isMobileLayout ? displayedColumnsMobile : displayedColumns;" [ngClass]="{'danger': row.deletedAt}"></mat-row>
</table>