import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Environment } from '../../../models/environment.model';
import { Observable } from 'rxjs';
import { MatchesResponse } from '../models/api/matches-response.model';
import { MessagesResponse } from '../models/api/messsages-response.model';


@Injectable({
  providedIn: 'root'
})
export class MatchesService {

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: Environment,
  ) { }

  getMatches(userId: number) {
    return this.httpClient.get<MatchesResponse>(`${this.environment.api.url}/admin/users/${userId}/matches`);
  }

  getMessages(matchId: number) {
    return this.httpClient.get<MessagesResponse>(`${this.environment.api.url}/admin/matches/${matchId}/messages`);
  }
}
