import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Environment } from '../../../models/environment.model';
import { Observable } from 'rxjs';
import { VerificationSession } from '../models/verification-session.model';


@Injectable({
  providedIn: 'root'
})
export class VerificationSessionsService {

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: Environment,
  ) { }

  getVerificationSessions(userId: number) {
    return this.httpClient.get<VerificationSession[]>(`${this.environment.api.url}/admin/users/${userId}/verification-sessions`);
  }

  getVerificationSession(sessionId: string) {
    return this.httpClient.get<VerificationSession>(`${this.environment.api.url}/admin/verification-sessions/${sessionId}`);
  }
}
