<div class="container-fluid mat-elevation-z8">
  <div class="loading-shade"
       *ngIf="isLoading || hasError">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <div class="has-error" *ngIf="hasError">
      There was an error loading the users.
    </div>
  </div>

  <div class="w-100 p-3">
    <mat-form-field class="w-100">
      <mat-label>Search Query</mat-label>
      <input matInput placeholder="Search Query" (change)="queryChanged($event)">
    </mat-form-field>
  </div>
  <div class="w-100 px-3">
    <sa-filters-container [filters]="filters | async" (onFiltersUpdated)="onFiltersUpdated($event)"></sa-filters-container>
  </div>
  <section class="w-100 p-3 d-flex justify-content-end">
    <mat-checkbox [checked]="hideBannedCountries | async" (change)="hideBannedCountries.next($event.checked)" class="me-2">Hide users from banned countries</mat-checkbox>
    <mat-checkbox [checked]="showDeletedUsers | async" (change)="showDeletedUsers.next($event.checked)">Show deleted users</mat-checkbox>
  </section>
  <div class="w-100">
    <sa-users-table
      [displayedColumns]="getDisplayedColumns()"
      [data]="data"
      [initialSort]="getSort()"
      (userClicked)="userClicked($event)"
      (userBanClicked)="userBanClicked($event)"
      (userUnBanClicked)="userUnBanClicked($event)"
      (userPutUnderReviewClicked)="userPutUnderReviewClicked($event)"
      (userRemoveFromReviewClicked)="userRemoveFromReviewClicked($event)"
      (userSilentHideClicked)="userSilentHideClicked($event)"
      (userSilentUnhideClicked)="userSilentUnhideClicked($event)"
      (userDeleteClicked)="userDeleteClicked($event)"
      (userBoostClicked)="userBoostClicked($event)"
      (userDeboostClicked)="userDeboostClicked($event)"
      (sortChange)="sortChanged($event)"
    >
    </sa-users-table>
  </div>

  <div class="container-fluid d-flex">
    <mat-paginator class="d-inline-block" [length]="resultsLength" [pageSize]="limit" [showFirstLastButtons]="true"></mat-paginator>
    <div class="d-flex flex-wrap align-content-center">
      <div class="pe-2">Go to </div>
      <input class="w-auto" matInput placeholder="page" (change)="pageChanged($event)"></div>
  </div>
</div>
