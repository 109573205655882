import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sa-info-field',
  templateUrl: './info-field.component.html',
  styleUrls: ['./info-field.component.scss']
})
export class InfoFieldComponent implements OnInit {
  @Input()
  label: string;

  constructor() { }

  ngOnInit(): void {
  }

}
