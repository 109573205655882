import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthToken } from '../models/auth.model';
import { User } from '../models/user.model';
import { Environment } from '../models/environment.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: Environment,
  ) {}

  logIn(email: string, password: string): Observable<AuthToken> {
    const url = `${this.environment.api.url}/users/authenticate`;
    return this.httpClient.post<AuthToken>(
      url,
      {
        method: 'admin',
        email,
        password
      }
    );
  }

  getMe(): Observable<User> {
    const url = `${this.environment.api.url}/admin/me`;
    return this.httpClient.get<User>(url);
  }
}
