import { Component } from '@angular/core';
import { Filter, FilterType } from 'src/app/modules/filtering/models';
import { UsersComponent } from '../users/users.component';

@Component({
  selector: 'sa-silent-hidden-users',
  templateUrl: '../users/users.component.html',
  styleUrls: ['../users/users.component.scss']
})
export class SilentHiddenUsersComponent extends UsersComponent {

  getFilter(): Filter[] {
    return [
      {
        type: FilterType.SILENT_HIDDEN,
        value: true,
        mutable: false
      }
    ];
  }
}
