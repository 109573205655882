<div class="container-fluid">
  <div class="row d-flex justify-content-center" *ngIf="isLoading || hasError">
    <mat-spinner diameter="30" *ngIf="isLoading"></mat-spinner>
    <div class="has-error" *ngIf="hasError">
      There was an error loading the verification session.
    </div>
  </div>
  <div class="row container-fluid flex-column" *ngIf="verificationSession">
    <div class="row">
      Verification ID: {{verificationSession.id}}
    </div>
    <div class="row">
      Status: {{verificationSession.status}}
    </div><div class="row">
      IP: {{verificationSession.ip_address}}
    </div>
    <div class="row" *ngIf="verificationSession.status == 'succeeded'">
      Liveness Confidence: {{verificationSession.liveness_confidence}}
    </div>
    <div class="row" *ngIf="verificationSession.liveness_ref_image_name">
      <button mat-button color="primary" (click)="onSeeImageClicked()">See Image</button>
    </div>
  </div>
</div>
