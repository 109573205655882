import { Injectable } from '@angular/core';
import { Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../store/selectors/auth.selectors';
import { State } from '../store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private authStore: Store<State>, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
    return this.authStore.select(selectAuthState).pipe(
      map(authState => (authState.auth !== null && authState.auth.authToken !== null)),
      tap(loggedIn => {
        if (!loggedIn) {
          this.router.navigate(['login']);
        }
      })
    );
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean  {
    return this.canActivate(null, null);
  }
}
