import { Component, Inject, Input, OnInit } from '@angular/core';
import { VerificationSessionsService } from '../../services/verification.service';
import { VerificationSession } from '../../models/verification-session.model';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Environment } from 'src/app/models/environment.model';

@Component({
  selector: 'sa-verification-session-inline',
  templateUrl: './verification-session-inline.component.html',
  styleUrls: ['./verification-session-inline.component.scss']
})
export class VerificationSessionInlineComponent implements OnInit {

  isLoading = true;
  hasError = false;

  @Input()
  verificationSessionId: string;

  verificationSession: VerificationSession;

  constructor(
    private verificationSessionsService: VerificationSessionsService,
    @Inject('environment') private environment: Environment,
  ) { }

  ngOnInit(): void {
    this.verificationSessionsService.getVerificationSession(this.verificationSessionId).pipe(
      tap(verificationSession => {
        this.isLoading = false;
        this.verificationSession = verificationSession;
      }),
      catchError(() => {
        this.isLoading = false;
        this.hasError = true;
        return of();
      })
    ).subscribe();
  }

  onSeeImageClicked() {
    window.open(`${this.environment.api.url}/admin/verification-sessions/${this.verificationSession.id}/ref-image`, "_blank");
  }
}
