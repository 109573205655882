import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { MatPaginator } from '@angular/material/paginator';
import { switchMap, map, catchError, tap, withLatestFrom } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users.service';
import { of, BehaviorSubject, merge, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import { ConfirmationService } from 'src/app/lib/services/confirmation.service';
import { ToastsService } from 'src/app/lib/services/toasts.service';
import { MatSort, Sort } from '@angular/material/sort';
import { faGhost } from '@fortawesome/free-solid-svg-icons';
import { CollectionResponse } from 'src/app/models/api.model';
import { Router } from '@angular/router';
import { Filter } from 'src/app/modules/filtering/models';

@Component({
  selector: 'sa-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements AfterViewInit {

  constructor(
    public dialog: MatDialog,
    public usersService: UsersService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private toastsService: ToastsService
  ) { }

  faGhost = faGhost;

  hideBannedCountries = new BehaviorSubject<boolean>(false);
  showDeletedUsers = new BehaviorSubject<boolean>(false);
  query = new BehaviorSubject<string>('');
  filters = new BehaviorSubject<Filter[]>(this.getFilter());
  sortChange = new BehaviorSubject<Sort>(this.getSort());

  data: User[] = [];

  resultsLength = 0;
  isLoading = true;
  hasError = false;
  limit = 30;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    merge(this.sortChange, this.paginator.page, this.query, this.filters, this.hideBannedCountries, this.showDeletedUsers).pipe(
      withLatestFrom(this.sortChange),
      switchMap(([event, sort]) => {
        this.isLoading = true;
        return this.usersService.getUsers(
          this.query.value,
          this.paginator.pageIndex + 1,
          this.limit,
          `${sort.active}:${sort.direction}`,
          this.filters.value,
          this.hideBannedCountries.value,
          this.showDeletedUsers.value,
        );
      }),
      map(usersCollection => {
        // Flip flag to show that loading has finished.
        this.isLoading = false;
        this.hasError = false;
        this.resultsLength = usersCollection.totalCount;

        return usersCollection.results;
      }),
      catchError(() => {
        this.isLoading = false;
        this.hasError = true;
        return of([]);
      })
    ).subscribe(data => this.data = data);
  }

  getDisplayedColumns(): string[] {
     return ['profile_image', 'actions', 'name', 'birth', 'gender', 'country', 'createdAt', 'last_activity', 'active', 'subscription_tier'];
  }

  getFilter(): Filter[] {
    return [];
  }

  getSort(): Sort {
    return { active: 'createdAt', direction: 'desc' }
  }

  sortChanged(e) {
    this.sortChange.next(e);
  }

  queryChanged(e) {
    this.paginator.pageIndex = 0;
    this.query.next(e.target.value);
  }

  pageChanged(e) {
    this.paginator.pageIndex = +e.target.value - 1;
    this.query.next(this.query.value);
  }

  onFiltersUpdated(filters) {
    this.filters.next(filters);
  }

  userClicked(user) {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      data: {
        user
      },
      minWidth: 400,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      if (result === 'edit') {
        this.router.navigate(['/users', user.user_id]);
      }
    });
  }

  userBanClicked(user) {
    this.usersService.banUser(user).pipe(
      tap((result) => {
        this.toastsService.showSuccess('Banned user successfully.');
        this.query.next(this.query.value);
      }),
      catchError((error) => {
        this.toastsService.showDanger('Error banning user: ' + JSON.stringify(error));
        return of([]);
      })
    ).subscribe();
  }

  userUnBanClicked(user) {
    this.confirmationService.askForConfirmation(`Unban ${user.name} ${user.lastname}?`, `Are you sure you want to unban ${user.name} ${user.lastname}?`)
      .then(() => {
        this.usersService.unBanUser(user).pipe(
          tap((result) => {
            this.toastsService.showSuccess('Unbanned user successfully.');
            this.query.next(this.query.value);
          }),
          catchError((error) => {
            this.toastsService.showDanger('Error unbanning user: ' + JSON.stringify(error));
            return of([]);
          })
        ).subscribe();
      })
      .catch(() => {});
  }

  userPutUnderReviewClicked(user) {
    this.usersService.putUserUnderReview(user).pipe(
      tap((result) => {
        this.toastsService.showSuccess('Put user under review successfully.');
        this.query.next(this.query.value);
      }),
      catchError((error) => {
        this.toastsService.showDanger('Error putting user under review: ' + JSON.stringify(error));
        return of([]);
      })
    ).subscribe();
  }

  userRemoveFromReviewClicked(user) {
    this.confirmationService.askForConfirmation(`Remove ${user.name} ${user.lastname} from review?`, `Are you sure you want to remove ${user.name} ${user.lastname} from review?`)
      .then(() => {
        this.usersService.removeUserFromReview(user).pipe(
          tap((result) => {
            this.toastsService.showSuccess('Removed user from review successfully.');
            this.query.next(this.query.value);
          }),
          catchError((error) => {
            this.toastsService.showDanger('Error removing user from review: ' + JSON.stringify(error));
            return of([]);
          })
        ).subscribe();
      })
      .catch(() => {});
  }

  userSilentHideClicked(user) {
    this.usersService.silentHideUser(user).pipe(
      tap((result) => {
        this.toastsService.showSuccess('Silent hid user successfully.');
        this.query.next(this.query.value);
      }),
      catchError((error) => {
        this.toastsService.showDanger('Error silent hiding user: ' + JSON.stringify(error));
        return of([]);
      })
    ).subscribe();
  }

  userSilentUnhideClicked(user) {
    this.confirmationService.askForConfirmation(`Unhide ${user.name} ${user.lastname}?`, `Are you sure you want to unhide ${user.name} ${user.lastname}?`)
      .then(() => {
        this.usersService.silentUnhideUser(user).pipe(
          tap((result) => {
            this.toastsService.showSuccess('Unhid user successfully.');
            this.query.next(this.query.value);
          }),
          catchError((error) => {
            this.toastsService.showDanger('Error unhiding user: ' + JSON.stringify(error));
            return of([]);
          })
        ).subscribe();
      })
      .catch(() => {});
  }

  userBoostClicked(user) {
    this.usersService.boostUser(user).pipe(
      tap((result) => {
        this.toastsService.showSuccess('Boosted user successfully.');
        this.query.next(this.query.value);
      }),
      catchError((error) => {
        this.toastsService.showDanger('Error boosting user: ' + JSON.stringify(error));
        return of([]);
      })
    ).subscribe();
  }

  userDeboostClicked(user) {
    this.usersService.deboostUser(user).pipe(
      tap((result) => {
        this.toastsService.showSuccess('Deboost user successfully.');
        this.query.next(this.query.value);
      }),
      catchError((error) => {
        this.toastsService.showDanger('Error deboosting user: ' + JSON.stringify(error));
        return of([]);
      })
    ).subscribe();
  }

  userDeleteClicked(user) {
    this.confirmationService.askForConfirmation(`Delete ${user.name} ${user.lastname}?`, `Are you sure you want to delete ${user.name} ${user.lastname}?`)
      .then(() => {
        this.usersService.deleteUser(user).pipe(
          tap((result) => {
            this.toastsService.showSuccess('Deleted user successfully.');
            this.query.next(this.query.value);
          }),
          catchError((error) => {
            this.toastsService.showDanger('Error deleting user: ' + JSON.stringify(error));
            return of([]);
          })
        ).subscribe();
      })
      .catch(() => {});
  }

}
