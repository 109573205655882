import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';
import { Auth } from 'src/app/models/auth.model';

export const authFeatureKey = 'auth';

export interface AuthState {
  auth?: Auth;
}

export const initialState: AuthState = {
  auth: null,
};


export const reducer = createReducer(
  initialState,

  on(AuthActions.authUser, state => state),
  on(AuthActions.authUserSuccess, (state, action) => {
    return {
      ...state,
      auth: {
        authToken: action.authToken,
        user: null,
      }
    };
  }),
  on(AuthActions.authUserFailure, (state, action) => state),

  on(AuthActions.getUser, (state) => state),
  on(AuthActions.getUserSuccess, (state, action) => {
    return {
      ...state,
      auth: {
        ...state.auth,
        user: action.user,
      }
    };
  }),
  on(AuthActions.getUserFailure, (state, action) => state),

  on(AuthActions.logout, (state, action) => {
    return {
      ...state,
      auth: null,
    };
  }),
);

