<mat-toolbar color="primary">
  <mat-toolbar-row class="d-none d-sm-flex nav">
    <a class="home-link me-3 d-inline-block" routerLink="/">Simarik Admin</a>
    <a class="nav-link d-inline-block" routerLink="/users"
      [routerLinkActive]="['is-active']">Users</a>
    <a class="nav-link d-inline-block" routerLink="/reported-users"
      [routerLinkActive]="['is-active']">Reported Users</a>
    <a class="nav-link d-inline-block" routerLink="/banned-users"
      [routerLinkActive]="['is-active']">Banned Users</a>
    <a class="nav-link d-inline-block" routerLink="/under-review-users"
      [routerLinkActive]="['is-active']">Under Review Users</a>
    <a class="nav-link d-inline-block" routerLink="/silent-hidden-users"
      [routerLinkActive]="['is-active']">Silent Hidden Users</a>
    <a class="nav-link d-inline-block" routerLink="/suspicious-users"
      [routerLinkActive]="['is-active']">Suspicious Users</a>
    <a class="nav-link d-inline-block ms-auto" routerLink="/tools"
      [routerLinkActive]="['is-active']">Tools</a>
  </mat-toolbar-row>

  <mat-toolbar-row class="d-sm-none d-flex align-items-center w-100">
    <span>Simarik Admin</span>
    <span class="spacer"></span>

    <button mat-icon-button
      [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
    <mat-menu #menu="matMenu">
      <button routerLink="/users" [routerLinkActive]="['is-active']"
        mat-menu-item>Users</button>
      <button routerLink="/reported-users" [routerLinkActive]="['is-active']"
        mat-menu-item>Reported Users</button>
      <button routerLink="/banned-users" [routerLinkActive]="['is-active']"
        mat-menu-item>Banned Users</button>
      <button routerLink="/under-review-users"
        [routerLinkActive]="['is-active']" mat-menu-item>Under Review
        Users</button>
      <button routerLink="/silent-hidden-users"
        [routerLinkActive]="['is-active']" mat-menu-item>Silent Hidden
        Users</button>
      <button routerLink="/suspicious-users" [routerLinkActive]="['is-active']"
        mat-menu-item>Suspicious Users</button>
      <button routerLink="/tools" [routerLinkActive]="['is-active']"
        mat-menu-item>Tools</button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>