import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { UsersComponent } from './components/users/users.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { SuspiciousUsersComponent } from './components/suspicious-users/suspicious-users.component';
import { BannedUsersComponent } from './components/banned-users/banned-users.component';
import { SilentHiddenUsersComponent } from './components/silent-hidden-users/silent-hidden-users.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { UnderReviewUsersComponent } from './components/under-review-users/under-review-users.component';
import { ReportedUsersComponent } from './components/reported-users/reported-users.component';

const routes: Routes = [
    {
      path: 'login',
      component: LoginComponent,
      canActivate: [LoginGuard],
    },
    {
      path: 'users',
      component: UsersComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'users/:id',
      component: EditUserComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'reported-users',
      component: ReportedUsersComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'banned-users',
      component: BannedUsersComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'under-review-users',
      component: UnderReviewUsersComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'silent-hidden-users',
      component: SilentHiddenUsersComponent,
      canActivate: [AuthGuard],
    },
    {
      path: 'suspicious-users',
      component: SuspiciousUsersComponent,
      canActivate: [AuthGuard],
    },


    // Tools module
    {
      path: 'tools',
      loadChildren: () => import('./modules/tools/tools.module')
          .then(m => m.ToolsModule),
    },


    {
      path: '**',
      redirectTo: '/users',
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
