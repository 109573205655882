import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CollectionResponse } from '../models/api.model';
import { User } from '../models/user.model';
import { ReportedUser } from '../models/reported-user.model';
import { Environment } from '../models/environment.model';
import { Filter, FilterType } from '../modules/filtering/models';
import { merge } from 'lodash';


const mapFilterToObject = (filter: Filter) => {
  switch (filter.type) {
    case FilterType.BANNED:
      return {
        is_banned: filter.value
      }
    case FilterType.SILENT_HIDDEN:
      return {
        hidden_silent: filter.value
      }
    case FilterType.HIDDEN_UNDER_REVIEW:
      return {
        hidden_under_review: filter.value
      }
    case FilterType.COUNTRY:
      return {
        detected_country: filter.value
      }
    case FilterType.COUNTRY_BORN:
      return {
        country_born: filter.value
      }
    case FilterType.BACKGROUND:
      return {
        background: filter.value
      }
    case FilterType.GENDER:
      return {
        gender: filter.value
      }
    case FilterType.INTERESTED_IN:
      return {
        interested_in: filter.value
      }
    case FilterType.STATUS:
      return {
        active: filter.value
      }
    case FilterType.SUBSCRIPTION_TIER:
      if(filter.value === 2) {
        return {
          is_select: true
        }
      } else if(filter.value === 1) {
        return {
          is_premium: true,
          is_select: false
        }
      } else {
        return {
          is_select: false,
          is_premium: false
        }
      }
    case FilterType.VERIFIED:
      return {
        is_verified: filter.value
      }
    case FilterType.BORN_AFTER:
      return {
        born_after: filter.value
      }
    case FilterType.BORN_BEFORE:
      return {
        born_before: filter.value
      }
    case FilterType.BORN_ON:
      return {
        born_on: filter.value
      }
    default:
      return {};
  }
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private httpClient: HttpClient,
    @Inject('environment') private environment: Environment,
  ) { }

  getUsers(query: string = null, page: number = 1, limit: number = 30, sort: string = null, filters: Filter[] = [], hideBannedCountries = false, showDeletedUsers = false) {
    let params = new HttpParams();

    if (query) {
      params = params.append('query', `${query}`);
    }
    params = params.append('page', `${page}`);
    params = params.append('limit', `${limit}`);

    if (sort) {
      params = params.append('sort', `${sort}`);
    }

    if (filters) {
      params = params.append(
        'filter',
        JSON.stringify(
          merge({}, ...filters.filter(filter => filter.type !== undefined && filter.value !== undefined).map(filter => mapFilterToObject(filter))),
        )
      );
    }

    params = params.append('hideBannedCountries', `${hideBannedCountries}`);
    params = params.append('showDeletedUsers', `${showDeletedUsers}`);

    return this.httpClient.get<CollectionResponse<User>>(`${this.environment.api.url}/admin/users`, {
      params
    });
  }

  updateUser(userId: number, user: Partial<User>) {
    return this.httpClient.patch<User>(`${this.environment.api.url}/admin/users/${userId}`, user);
  }

  getUser(userId: number) {
    return this.httpClient.get<User>(`${this.environment.api.url}/admin/users/${userId}`);
  }

  getReportedUsers(query: string = null, page: number = 1, limit: number = 30, sort: string = null, filters: Filter[] = [], fromCarliminator = false) {
    let params = new HttpParams();

    if (query) {
      params = params.append('query', `${query}`);
    }
    params = params.append('page', `${page}`);
    params = params.append('limit', `${limit}`);

    if (sort) {
      params = params.append('sort', `${sort}`);
    }
    if (filters) {
      params = params.append(
        'filter',
        JSON.stringify(
          merge({}, ...filters.filter(filter => filter.type !== undefined && filter.value !== undefined).map(filter => mapFilterToObject(filter))),
        )
      );
    }

    params = params.append('from_carliminator', `${fromCarliminator}`);

    return this.httpClient.get<CollectionResponse<ReportedUser>>(`${this.environment.api.url}/admin/reported-users`, {
      params
    });
  }

  banUser(user: User) {
    return this.httpClient.post(`${this.environment.api.url}/admin/users/${user.user_id}/ban`, null);
  }

  unBanUser(user: User) {
    return this.httpClient.post(`${this.environment.api.url}/admin/users/${user.user_id}/unban`, null);
  }

  putUserUnderReview(user: User) {
    return this.httpClient.post(`${this.environment.api.url}/admin/users/${user.user_id}/put-under-review`, null);
  }

  removeUserFromReview(user: User) {
    return this.httpClient.post(`${this.environment.api.url}/admin/users/${user.user_id}/remove-from-review`, null);
  }

  silentHideUser(user: User) {
    return this.httpClient.post(`${this.environment.api.url}/admin/users/${user.user_id}/silent-hide`, null);
  }

  silentUnhideUser(user: User) {
    return this.httpClient.post(`${this.environment.api.url}/admin/users/${user.user_id}/silent-unhide`, null);
  }

  boostUser(user: User) {
    return this.httpClient.post(`${this.environment.api.url}/admin/users/${user.user_id}/boost`, null);
  }

  deboostUser(user: User) {
    return this.httpClient.post(`${this.environment.api.url}/admin/users/${user.user_id}/deboost`, null);
  }

  deleteUser(user: User) {
    return this.httpClient.post(`${this.environment.api.url}/admin/users/${user.user_id}/delete`, null);
  }
}
