import { Component } from '@angular/core';
import { Filter, FilterType } from 'src/app/modules/filtering/models';
import { UsersComponent } from '../users/users.component';

@Component({
  selector: 'sa-under-review-users',
  templateUrl: '../users/users.component.html',
  styleUrls: ['../users/users.component.scss']
})
export class UnderReviewUsersComponent extends UsersComponent {

  getFilter(): Filter[] {
    return [{
      type: FilterType.HIDDEN_UNDER_REVIEW,
      value: true,
      mutable: false
    }];
  }
}
