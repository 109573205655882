import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfoFieldComponent } from './components/info-field/info-field.component';
import { ToastsComponent } from './components/toasts/toasts/toasts.component';
import { EditableTextFieldComponent } from './components/editable-textfield/editable-textfield.component';
// import { MatFormField } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [InfoFieldComponent, EditableTextFieldComponent, ToastsComponent],
  imports: [
    CommonModule,
    NgbModule,
    MatInputModule,
    MatFormFieldModule,
  ],
  exports: [
    InfoFieldComponent,
    EditableTextFieldComponent,
    ToastsComponent,
  ]
})
export class LibModule { }
