import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { VerificationSessionInlineComponent } from './components/verification-session-inline/verification-session-inline.component';

@NgModule({
  declarations:[VerificationSessionInlineComponent],
  exports: [VerificationSessionInlineComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ]
})
export class VerificationModule {}