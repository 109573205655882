<div class="container my-5">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field class="w-100">
      <mat-label>Name</mat-label>
      <input matInput [formControl]="form.controls.name">
      <mat-error *ngIf="form.controls.name.hasError('name') && !form.controls.name.hasError('required')">
        Please enter a valid name
      </mat-error>
      <mat-error *ngIf="form.controls.name.hasError('required')">
        Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Last Name</mat-label>
      <input matInput [formControl]="form.controls.lastname">
      <mat-error *ngIf="form.controls.lastname.hasError('lastname') && !form.controls.lastname.hasError('required')">
        Please enter a valid last name
      </mat-error>
      <mat-error *ngIf="form.controls.name.hasError('required')">
        Last name is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Birthday</mat-label>
      <input matInput [formControl]="form.controls.birth">
      <mat-error *ngIf="form.controls.birth.hasError('pattern') && !form.controls.birth.hasError('required')">
        Please enter a valid birthday
      </mat-error>
      <mat-error *ngIf="form.controls.birth.hasError('required')">
        Birthday is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Email</mat-label>
      <input matInput [formControl]="form.controls.email">
      <mat-error *ngIf="form.controls.email.hasError('email') && !form.controls.email.hasError('required')">
        Please enter a valid email
      </mat-error>
      <mat-error *ngIf="form.controls.email.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Phone Number</mat-label>
      <input matInput [formControl]="form.controls.phone_number">
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Gender</mat-label>
      <mat-select [formControl]="form.controls.gender">
        <mat-option value="male">Male</mat-option>
        <mat-option value="female">Female</mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls.email.hasError('required')">
        Gender is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <mat-label>Interested In</mat-label>
      <mat-select [formControl]="form.controls.interested_in">
        <mat-option value="male">Male</mat-option>
        <mat-option value="female">Female</mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls.interested_in.hasError('required')">
        Interested in is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <button type="submit" mat-flat-button color="primary" class='w-100'>Save</button>
  </form>
</div>