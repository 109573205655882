import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import * as fromStore from '../../store';
import { Store } from '@ngrx/store';
import { authUser } from 'src/app/store/actions/auth.actions';

@Component({
  selector: 'sa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: UntypedFormGroup;

  constructor(private store: Store<fromStore.State>, private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email,
      ]],
      password: ['', [
        Validators.required,
      ]],
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.store.dispatch(authUser({
      email: this.form.controls.email.value,
      password: this.form.controls.password.value,
    }));
  }

}
