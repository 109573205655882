<div class="d-flex flex-wrap">
  <sa-filter 
    *ngFor="let filter of filters; let i = index"
    [filter]="filter"
    (deleteFilterClicked)="deleteFilterClicked($event)"
    (onFilterUpdate)="onFilterUpdate($event)"
  ></sa-filter>
  <button mat-button class="add-filter" color="primary" aria-label="Add Filter" (click)="onClickAddFilter()">
    <mat-icon>add</mat-icon> Add Filter
  </button>
</div>
