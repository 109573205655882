<div class="matches-container container-fluid mt-3">
  <div class="row">
    <h2>{{user.name}}'s Matches</h2>
  </div>
  <div class="row d-flex justify-content-center" *ngIf="isLoading || hasError">
    <mat-spinner diameter="30" *ngIf="isLoading"></mat-spinner>
    <div class="has-error" *ngIf="hasError">
      There was an error loading the users.
    </div>
  </div>
  <div class="row">
    <div class="d-flex match-user-container flex-column align-items-stretch">
      <button mat-button class="user-match d-flex" [ngClass]="{'user-match-reported': reportedUser && reportedUser.reporting_user_id === match.target_id}" *ngFor="let match of matches; let i = index" (click)="selectedMatch.next(match)">
        <div class="d-flex flex-column">
          <div class="d-flex">
            <div>
              <img class="match-avatar rounded-circle" [src]="match.target_image" />
            </div>
            <div class="match-name body-2">
              {{match.target_name}} <mat-icon color="primary" class="verified-icon" *ngIf="match.is_verified">verified</mat-icon>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="match-badges">
              <span class="badge bg-dark mt-2" *ngIf="match.friendship">Friendship</span>
              <span class="badge bg-dark mt-2" *ngIf="match.direct_message">Direct Message</span>
              <span class="badge bg-warning mt-2" *ngIf="match.from_superlike">From Super Like</span>
              <span class="badge bg-light mt-2" *ngIf="reportedUser && reportedUser.reporting_user_id === match.target_id">Reporting Party</span>
              <span class="badge bg-danger mt-2" *ngIf="match.deletedAt">Deleted</span>
            </div>
          </div>
          <div class="d-flex">
            <div class="match-created-at">{{match.createdAt}}</div>
          </div>
        </div>
      </button>
    </div>
    <div class="px-3 match-message-container">
      <sa-match-messages class="match-messages" *ngIf="(selectedMatch | async) !== null" [match]="selectedMatch | async"></sa-match-messages>
    </div>
  </div>
</div>