import { Injectable, Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sa-confirmation',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ body }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('Ok click')">Ok</button>
  </div>
  `
})
export class SAConfirmationComponent {
  @Input()
  title: string;

  @Input()
  body: string;

  constructor(public modal: NgbActiveModal) {}
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {

  constructor(private modalService: NgbModal) { }

  askForConfirmation(title, body) {
    const modalRef = this.modalService.open(SAConfirmationComponent);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.body = body;

    return modalRef.result;
  }
}
