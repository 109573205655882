<h2 mat-dialog-title>{{ user.name }} {{ user.lastname }} <mat-icon color="primary" class="verified-icon" *ngIf="user.is_verified">verified</mat-icon></h2> 
<mat-dialog-content class="mat-typography">
  <mat-tab-group mat-stretch-tabs="false">
    <mat-tab label="User Info">
      <sa-user-info [user]="user"></sa-user-info>
    </mat-tab>
    <mat-tab label="Matches">
      <sa-user-matches [user]="user" [reportedUser]="reportedUser"></sa-user-matches>
    </mat-tab>
  </mat-tab-group>
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close="edit">Edit User</button>
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>