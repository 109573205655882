import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Filter, FilterType, optionValues } from '../../models';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';

@Component({
  selector: 'sa-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  providers:[
    provideMomentDateAdapter({
      parse: {
        dateInput: ['YYYY-MM-DD']
      },
      display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    }),
  ],
})
export class FilterComponent implements OnInit {
  @Input()
  filter: Filter;

  @Output() deleteFilterClicked = new EventEmitter<Filter>();
  @Output() onFilterUpdate = new EventEmitter<Filter>();

  FilterType = FilterType;
  filterTypes = Object.values(FilterType);

  selectorOptions = [];

  ngOnInit(): void {
    this.selectorOptions = optionValues[this.filter.type]?.values;
  }

  onChangeType(value: FilterType) {
    this.filter.type = value;

    // Only fire filter update if the filter did have a value before
    if(this.filter.value !== undefined) {
      this.filter.value = undefined;
      this.onFilterUpdate.next(this.filter);
    }

    this.filter.value = undefined;
    this.selectorOptions = optionValues[this.filter.type]?.values;
  }

  onChangeValue(value) {
    console.log(value);
    this.filter.value = value;
    this.onFilterUpdate.next(this.filter);
  }

  comparerFn = (filter1:FilterType, filter2:FilterType ) => filter1 === filter2;
  comparerFn2 = (filter1:boolean, filter2:boolean ) => filter1 === filter2;
  comparerFn3 = (filter1:string, filter2:string ) => filter1 === filter2;

  displayTitleForValue(value) {
    return this.selectorOptions.find(selectorOption => selectorOption.value === value).title
  }
}
