import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ReportedUser } from 'src/app/models/reported-user.model';
import { User } from 'src/app/models/user.model';
import { Match } from '../../models/match.model';
import { MatchesService } from '../../services/matches.service';

@Component({
  selector: 'sa-user-matches',
  templateUrl: './user-matches.component.html',
  styleUrls: ['./user-matches.component.scss']
})
export class UserMatchesComponent implements OnInit {

  @Input()
  user: User;

  @Input()
  reportedUser: ReportedUser;

  matches: Match[];

  selectedMatch = new BehaviorSubject<Match>(null);

  isLoading = true;
  hasError = false;

  constructor(
    private matchesService: MatchesService,
  ) { }

  ngOnInit(): void {
    this.matchesService.getMatches(this.user.user_id).pipe(
      tap(matchesResponse => {
        this.isLoading = false;
        this.matches = matchesResponse.matches;
      }),
      catchError(() => {
        this.isLoading = false;
        this.hasError = true;
        return of([]);
      })
    ).subscribe();
  }
}
