<div class="d-flex filter-chip">
  <div 
    class="select-button chip-title d-flex" 
    [ngClass]="{'mutable': filter.mutable}"
  >
    <div class="d-flex align-self-center">
      <div class="align-self-center">{{filter.type || 'Select a Filter'}}</div>
      <mat-icon *ngIf="filter.mutable" class="picker-icon align-self-center d-flex align-items-center">arrow_drop_down</mat-icon>
    </div> 
    
    <mat-select *ngIf="filter.mutable" panelClass="select-panel"  [compareWith]="comparerFn" (selectionChange)="onChangeType($event.value)">
      <mat-option *ngFor="let filterType of filterTypes" [value]="filterType">
        {{ filterType }}
      </mat-option>
    </mat-select>
  </div>
  <div
    class="select-button chip-value d-flex"
  >
  @if (filter.type === FilterType.BORN_AFTER || filter.type === FilterType.BORN_BEFORE || filter.type === FilterType.BORN_ON) {
    <input class="date-input" matInput placeholder="YYYY-MM-DD" [matDatepicker]="picker" (dateChange)="onChangeValue($event.value.format('YYYY-MM-DD'))">
    <mat-datepicker #picker></mat-datepicker>
    <mat-icon  class="picker-icon align-self-center d-flex align-items-center" (click)="picker.open()">arrow_drop_down</mat-icon>
  } @else {
    <div class="chip-text d-flex align-self-center" [ngClass]="{'mutable': filter.mutable}">
      <div class="align-self-center">{{filter.value === undefined ? 'Select a value' : (displayTitleForValue(filter.value))}}</div>
      <mat-icon *ngIf="filter.mutable" class="picker-icon align-self-center d-flex align-items-center">arrow_drop_down</mat-icon>
    </div> 
    
    <mat-select *ngIf="filter.mutable" panelClass="select-panel" [compareWith]="comparerFn" (selectionChange)="onChangeValue($event.value)">
      <mat-option *ngFor="let selectorOption of selectorOptions" [value]="selectorOption.value">
        {{ selectorOption.title }}
      </mat-option>
    </mat-select>
  }
    <button class="delete-button align-self-center d-flex" color="primary" *ngIf="filter.mutable" aria-label="Delete filter" (click)="deleteFilterClicked.next(filter)">
      <mat-icon class="align-self-center" aria-label="Delete filter">cancel</mat-icon>
    </button>
  </div>
</div>
