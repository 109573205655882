import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {v4 as uuidv4} from 'uuid';
import { Filter } from '../../models';

@Component({
  selector: 'sa-filters-container',
  templateUrl: './filters-container.component.html',
  styleUrls: ['./filters-container.component.scss']
})
export class FiltersContainerComponent implements OnInit {

  @Input()
  filters: Filter[];

  @Output() onFiltersUpdated = new EventEmitter<Filter[]>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickAddFilter() {
    this.filters.push({
      temp_id: uuidv4(),
      mutable: true,
    });
  }

  deleteFilterClicked(removedFilter: Filter) {
    this.filters = this.filters.filter(filter => filter.temp_id !== removedFilter.temp_id);
    this.onFiltersUpdated.next(this.filters);
  }

  onFilterUpdate(updatedFilter: Filter) {
    this.filters[this.filters.findIndex(filter => filter.temp_id === updatedFilter.temp_id)] = updatedFilter;
    this.onFiltersUpdated.next(this.filters);
  }
}
