<div class="container-fluid">
  <div class="row">
    <h3>Messages with {{match.target_name}} ({{match.target_id}})</h3>
  </div>
  <div class="row d-flex justify-content-center" *ngIf="isLoading || hasError">
    <mat-spinner diameter="30" *ngIf="isLoading"></mat-spinner>
    <div class="has-error" *ngIf="hasError">
      There was an error loading the users.
    </div>
  </div>
  <div class="row d-flex flex-column">
    <div *ngFor="let message of messages; let i = index" class="match-message d-flex flex-column py-3">
      <div class="d-flex flex-row mb-2">
        <img class="message-avatar rounded-circle align-self-center " [src]="message.from_image" />
        <span class="align-self-center ms-2 text-bold">{{message.from_name}}</span>
        <span class="align-self-center ms-auto me-1">{{message.createdAt}}</span>
      </div>
      <div class="d-flex flex-row px-3">
        <span>{{message.text}}</span>
      </div>
    </div>
  </div>
</div>
