import { createAction, props } from '@ngrx/store';
import { AuthToken } from 'src/app/models/auth.model';
import { User } from 'src/app/models/user.model';

export const authUser = createAction(
  '[Auth] Auth User',
  props<{ email: string; password: string }>(),
);

export const authUserSuccess = createAction(
  '[Auth] Auth User Success',
  props<{ authToken: AuthToken }>()
);

export const authUserFailure = createAction(
  '[Auth] Auth User Failure',
  props<{ error: any }>()
);

export const getUser = createAction(
  '[Auth] Get User',
);

export const getUserSuccess = createAction(
  '[Auth] Get User Success',
  props<{ user: User }>()
);

export const getUserFailure = createAction(
  '[Auth] Get User Failure',
  props<{ error: any }>()
);

export const logout = createAction(
  '[Auth] Logout',
);
