import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportedUser } from 'src/app/models/reported-user.model';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'sa-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {
  user: User;
  reportedUser: ReportedUser;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.user = this.data.user;
    this.reportedUser = this.data.reportedUser;
  }

  ngOnInit(): void {
  }

}
