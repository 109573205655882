<div class="container-fluid mat-elevation-z8">
  <div class="loading-shade"
       *ngIf="isLoading || hasError">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <div class="has-error" *ngIf="hasError">
      There was an error loading the users.
    </div>
  </div>

  <div class="w-100 p-3">
    <mat-form-field class="w-100">
      <mat-label>Search Query</mat-label>
      <input matInput placeholder="Search Query" (change)="queryChanged($event)">
    </mat-form-field>
  </div>
  <div class="w-100 px-3">
    <sa-filters-container [filters]="filters | async" (onFiltersUpdated)="onFiltersUpdated($event)"></sa-filters-container>
  </div>
  <div class="w-100">
    <table mat-table [dataSource]="data" class="users-table w-100" matSort matSortActive="createdAt" matSortDisableClear matSortDirection="desc" matSortStart="desc">
      <ng-container matColumnDef="profile_image">
        <mat-header-cell mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">
          <div class="profile-image-container">
            <img class="profile-image m-1" *ngIf="row.user.profile_images[0]" [src]="row.user.profile_images[0].image_url"/>
            <img class="profile-image-extended m-1" *ngIf="row.user.profile_images[0]" [src]="row.user.profile_images[0].image_url"/>
          </div>
          
          <div class="profile-image-container">
            <img class="profile-image m-1" *ngIf="row.user.profile_images[1]" [src]="row.user.profile_images[1].image_url"/>
            <img class="profile-image-extended m-1" *ngIf="row.user.profile_images[1]" [src]="row.user.profile_images[1].image_url"/>
          </div>
    
          <div class="profile-image-container">
            <img class="profile-image m-1" *ngIf="row.user.profile_images[2]" [src]="row.user.profile_images[2].image_url"/>
            <img class="profile-image-extended m-1" *ngIf="row.user.profile_images[2]" [src]="row.user.profile_images[2].image_url"/>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="profile_image_mobile">
        <mat-header-cell mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">
          <div class="profile-image-container">
            <img class="profile-image m-1" *ngIf="row.user.profile_images[0]" [src]="row.user.profile_images[0].image_url"/>
            <img class="profile-image-extended m-1" *ngIf="row.user.profile_images[0]" [src]="row.user.profile_images[0].image_url"/>
          </div>
          
          <div class="profile-image-container">
            <img class="profile-image m-1" *ngIf="row.user.profile_images[1]" [src]="row.user.profile_images[1].image_url"/>
            <img class="profile-image-extended m-1" *ngIf="row.user.profile_images[1]" [src]="row.user.profile_images[1].image_url"/>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">
          <button mat-icon-button color="primary" (click)="userClicked(row)" aria-label="View">
            <mat-icon>visibility</mat-icon>
          </button>
          <button mat-icon-button color="primary" *ngIf="!row.user.hidden_under_review" (click)="userPutUnderReviewClicked(row.user)" aria-label="Ban">
            <mat-icon>remove_circle</mat-icon>
          </button>
          <button mat-icon-button color="primary" *ngIf="row.user.hidden_under_review" (click)="userRemoveFromReviewClicked(row.user)" aria-label="Unban">
            <mat-icon>add_circle</mat-icon>
          </button>
          <button mat-icon-button class="fa-button" color="primary" *ngIf="!row.user.is_banned" (click)="userBanClicked(row.user)" aria-label="Ban">
            <fa-icon [icon]="faSkullCrossbones"></fa-icon>
          </button>
          <button mat-icon-button class="fa-button fa-button-gray" *ngIf="row.user.is_banned" (click)="userUnBanClicked(row.user)" aria-label="Unban">
            <fa-icon [icon]="faSkullCrossbones"></fa-icon>
          </button>
          <button mat-icon-button class="fa-button" color="primary" *ngIf="!row.user.hidden_silent" (click)="userSilentHideClicked(row.user)" aria-label="Ban">
            <fa-icon [icon]="faGhost"></fa-icon>
          </button>
          <button mat-icon-button class="fa-button fa-button-gray" *ngIf="row.user.hidden_silent" (click)="userSilentUnhideClicked(row.user)" aria-label="Unban">
            <fa-icon [icon]="faGhost"></fa-icon>
          </button>
          <button mat-icon-button color="primary" (click)="userDeleteClicked(row.user)" aria-label="Delete">
            <mat-icon>delete_forever</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="userBoostClicked(row.user)" aria-label="Boost">
            <mat-icon>arrow_circle_up</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="userDeboostClicked(row.user)" aria-label="Deboost">
            <mat-icon>arrow_circle_down</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions_mobile">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">
          <button mat-icon-button color="primary" (click)="userClicked(row)" aria-label="View">
            <mat-icon>visibility</mat-icon>
          </button>
          <button mat-icon-button color="primary" *ngIf="!row.user.hidden_under_review" (click)="userBanClicked(row.user)" aria-label="Ban">
            <mat-icon>remove_circle</mat-icon>
          </button>
          <button mat-icon-button color="primary" *ngIf="row.user.hidden_under_review" (click)="userUnBanClicked(row.user)" aria-label="Unban">
            <mat-icon>add_circle</mat-icon>
          </button>
          <button mat-icon-button class="fa-button" color="primary" *ngIf="!row.user.hidden_silent" (click)="userSilentHideClicked(row.user)" aria-label="Ban">
            <fa-icon [icon]="faGhost"></fa-icon>
          </button>
          <button mat-icon-button class="fa-button fa-button-gray" *ngIf="row.user.hidden_silent" (click)="userSilentUnhideClicked(row.user)" aria-label="Unban">
            <fa-icon [icon]="faGhost"></fa-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row.user.name}} <mat-icon color="primary" class="verified-icon" *ngIf="row.user.is_verified">verified</mat-icon></mat-cell>
      </ng-container>

      <ng-container matColumnDef="gender">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Gender</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row.user.gender}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="country">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Country</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row.user.detected_country}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Reported At</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row.createdAt | date: 'medium'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="last_activity">
        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Last Activity</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row.user.last_activity | date: 'medium'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row.user.active ? 'Active' : 'Inactive'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="subscription_tier">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Subscription Tier</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row.user.is_select ? 'Select' : (row.user.is_premium ? 'Premium' : 'Basic')}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="hidden_no_picture">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Hidden No Picture</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row.user.hidden_no_picture ? 'Yes' : 'No'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="hidden_under_review">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Under Review</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row.user.hidden_under_review ? 'Yes' : 'No'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="count">
        <mat-header-cell mat-header-cell *matHeaderCellDef># (same)</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row.report_count}} ({{row.same_report_count}})</mat-cell>
      </ng-container>

      <ng-container matColumnDef="reason">
        <mat-header-cell mat-header-cell *matHeaderCellDef>Reason</mat-header-cell>
        <mat-cell mat-cell *matCellDef="let row">{{row.reason}}</mat-cell>
      </ng-container>
      

      <mat-header-row *matHeaderRowDef="isMobileLayout ? displayedColumnsMobile : displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: isMobileLayout ? displayedColumnsMobile : displayedColumns;"></mat-row>
    </table>
  </div>

  <div class="container-fluid d-flex">
    <mat-paginator class="d-inline-block" [length]="resultsLength" [pageSize]="limit" [showFirstLastButtons]="true"></mat-paginator>
    <div class="d-flex flex-wrap align-content-center">
      <div class="pe-2">Go to </div>
      <input class="w-auto" matInput placeholder="page" (change)="pageChanged($event)"></div>
  </div>
</div>
