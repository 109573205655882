import { Component, TemplateRef } from '@angular/core';
import { ToastsService } from 'src/app/lib/services/toasts.service';

@Component({
  selector: 'sa-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss']
})
export class ToastsComponent {

  constructor(public toastsService: ToastsService) {}

  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}
